import EditableNumberInput from '@/components/EditableNumberInput';
import { UPDATE_PLATFORM_MUTATION } from '@/lib/reportsApi';
import { formatValue, parseValue } from '@/routes/utils';
import { useMutation } from '@apollo/client';
import { Form, InputNumber, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, useEffect } from 'react';

type Props = {
	spend: number;
	record: any;
	updateField: string;
	successMessage: string;
	refetch: any;
	prefix?: string;
};

const SpendCell: FC<Props> = ({
	spend,
	record,
	updateField,
	successMessage,
	refetch,
	prefix,
}: Props) => {
	const [updateReportPlatform, { loading, data, error }] = useMutation(
		UPDATE_PLATFORM_MUTATION
	);
	const handleOnBlur = async () => {
		const newSpend = form.getFieldValue(updateField);
		if (newSpend !== undefined && newSpend !== spend) {
			await updateReportPlatform({
				variables: { id: record.id, data: { [updateField]: newSpend } },
			});
			refetch();
		}
	};

	useEffect(() => {
		if (data) {
			message.success(successMessage);
		} else if (error) {
			message.error(error.message);
		}
	}, [data, error, successMessage]);

	const [form] = useForm();

	useEffect(() => {
		Object.keys(record).forEach((k) => {
			if (record[k] !== form.getFieldValue(k)) {
				form.setFieldValue(k, record[k]);
			}
		});
	}, [record]);

	return (
		<Form form={form} className="pr-1">
			<div className="flex h-9 items-center justify-center">
				<Form.Item name={updateField} initialValue={spend} noStyle>
					<EditableNumberInput
						inputValue={spend}
						inputProps={{
							className: ' w-full',
							disabled: loading,
							size: 'middle',
							prefix: prefix,
						}}
						handleConfirm={handleOnBlur}
						handleChange={(value) => form.setFieldValue(updateField, value)}
					/>
					{/* <InputNumber
						className=" w-full"
						disabled={loading}
						size="middle"
						onBlur={handleOnBlur}
						prefix={prefix}
						formatter={formatValue}
						parser={parseValue}
						onChange={(value) => form.setFieldValue(updateField, value)}
					/> */}
				</Form.Item>
			</div>
		</Form>
	);
};

export default SpendCell;
