import { useQuery } from '@apollo/client';
import {
	REPORT_PLATFORM_AUDIT,
	ReportPlatformAuditData,
	ReportPlatformAuditVariables,
} from '@/lib/auditApi';

import { AuthContext } from '@/context/AuthContext';
import AuditModal from '@/routes/ClientServicesPage/components/AuditModal';
import DeletePlatform from '@/routes/ClientServicesPage/components/DeletePlatform';
import DuplicatePlatformModal from '@/routes/ClientServicesPage/components/PlatformModal/DuplicatePlatformModal';
import EditPlatformModal from '@/routes/ClientServicesPage/components/PlatformModal/EditPlatformModal';
import NotesModal from '@/routes/ClientServicesPage/components/PlatformModal/NotesModal';
import {
	CopyOutlined,
	DeleteOutlined,
	EditOutlined,
	FileOutlined,
	HistoryOutlined,
	MoreOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import { useContext, useState, useEffect } from 'react';

import dayjs from 'dayjs';
import { PiInvoice } from 'react-icons/pi';
import ReconciliationModal from '@/routes/ClientServicesPage/components/ReconciliationModal';
import type { Report } from '@/interfaces/Report';

type Props = {
	reportId: string;
	report: Report;
	platform: any;
	refetch: () => void;
	refetchReports: () => void;
};

const PlatformActions = ({
	platform,
	refetch,
	reportId,
	report,
	refetchReports,
}: Props) => {
	const [open, setOpen] = useState('none');
	const [hovered, setHovered] = useState(false);
	const { user } = useContext(AuthContext);

	const {
		loading,
		data,
		refetch: refetchAudit,
	} = useQuery<ReportPlatformAuditData, ReportPlatformAuditVariables>(
		REPORT_PLATFORM_AUDIT,
		{
			variables: { platform: platform.id },
		}
	);

	useEffect(() => {
		if (hovered) {
			refetchAudit();
		}
	}, [hovered, refetchAudit]);

	const lastModified = data?.audits?.[0]?.timestamp
		? dayjs(data.audits[0].timestamp).format('D MMM YYYY HH:mm')
		: 'N/A';

	const noteText =
		platform.notes?.document
			?.map((node: any) =>
				node.children.map((child: any) => child.text).join('')
			)
			.join('') || '';
	const noteLength = noteText.length;

	const items: MenuProps['items'] = [
		{
			key: 'notes',
			icon: <FileOutlined />,
			label: 'Notes',
		},
		{
			key: 'edit',
			icon: <EditOutlined />,
			label: 'Edit',
		},
		{
			key: 'duplicate',
			icon: <CopyOutlined />,
			label: 'Duplicate',
		},
		...(user?.permission?.reconciliationDataSeePage === 'yes'
			? [
					{
						key: 'reconcile',
						icon: <PiInvoice />,
						label: 'Reconcile',
					},
			  ]
			: []),
		{
			key: 'audit',
			icon: <HistoryOutlined />,
			label: 'Audit',
		},
		...(user?.permission?.clientServicesDeleteCampaign === 'no'
			? []
			: [
					{
						key: 'delete',
						danger: true,
						icon: <DeleteOutlined />,
						label: 'Delete',
					},
			  ]),
	];

	return (
		<>
			<Dropdown
				arrow
				placement="bottom"
				trigger={['click']}
				menu={{
					items,
					onClick: (item) => {
						setOpen(item.key);
					},
				}}>
				<span
					className="flex cursor-pointer items-center space-x-2 text-xl"
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}>
					<MoreOutlined className="cursor-pointer text-xl" />
					<span className="flex items-center space-x-2">
						{loading ? (
							<LoadingOutlined style={{ fontSize: 15 }} spin />
						) : (
							<>
								<Tooltip title="New Note">
									<span
										className={
											loading || noteLength === 0 ? 'opacity-20' : 'opacity-100'
										}>
										<FileOutlined style={{ fontSize: 15 }} />
									</span>
								</Tooltip>

								<Tooltip title={lastModified}>
									<span
										className={
											loading || lastModified === 'N/A'
												? 'opacity-20'
												: 'opacity-100'
										}>
										<HistoryOutlined style={{ fontSize: 15 }} />
									</span>
								</Tooltip>
							</>
						)}
					</span>
				</span>
			</Dropdown>

			<EditPlatformModal
				platform={platform}
				refetch={refetch}
				open={open === 'edit'}
				setOpen={setOpen}
			/>
			<NotesModal
				open={open === 'notes'}
				setOpen={setOpen}
				notes={platform.notes}
				platform={platform}
				refetch={refetch}
			/>
			<AuditModal
				platform={platform}
				open={open === 'audit'}
				setOpen={setOpen}
				lastModified={lastModified}
			/>
			<DeletePlatform
				refetchReports={refetchReports}
				platformId={platform.id}
				refetch={refetch}
				open={open === 'delete'}
				setOpen={setOpen}
			/>
			<DuplicatePlatformModal
				refetchReports={refetchReports}
				reportId={reportId}
				platform={platform}
				refetch={refetch}
				open={open === 'duplicate'}
				setOpen={setOpen}
			/>
			{open === 'reconcile' && (
				<ReconciliationModal
					report={report}
					platform={platform}
					setOpen={setOpen}
					open={open === 'reconcile'}
					rows={platform.financialRows}
				/>
			)}
		</>
	);
};

export default PlatformActions;
