import type { ReportPlatform } from '@/interfaces/ReportPlatform';
import { BUYING_TYPES, SECONDARY_KPI_TYPES } from '@/lib/enums';
import { UPDATE_PLATFORM_MUTATION } from '@/lib/reportsApi';
import { getFormattedNumber } from '@/lib/utils';
import { CalculatorOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { useMemo } from 'react';

type Props = {
	platform: ReportPlatform;
	currency: string;
	refetch: () => void;
};

const calculateEffectiveCost = (platform: ReportPlatform) => {
	let spend = 0;
	let cost = 0;
	if (platform.objectiveType === 'absolute') {
		spend =
			platform.buyingType === BUYING_TYPES.CPM ||
			platform.buyingType === BUYING_TYPES.CPR
				? platform.objectiveSpend / 1000
				: platform.objectiveSpend;
		cost = platform.cost / spend;
	} else if (platform.objectiveType === 'budget') {
		spend =
			platform.secondaryKPIType === SECONDARY_KPI_TYPES.Impressions ||
			platform.buyingType === SECONDARY_KPI_TYPES.Reach
				? platform.secondaryKPISpend / 1000
				: platform.secondaryKPISpend;
		cost = platform.cost / spend;
	}
	return cost;
};

const EffectiveCostCalculate = ({ platform, currency, refetch }: Props) => {
	const [updateReportPlatform, { loading, data, error }] = useMutation(
		UPDATE_PLATFORM_MUTATION
	);
	const effectiveCost = useMemo(() => {
		if (
			platform.objectiveType === 'absolute' &&
			(platform.cost === null || platform.cost === 0) &&
			(platform.objectiveSpend === null || platform.objectiveSpend === 0)
		) {
			return 0;
		} else if (
			platform.objectiveType === 'budget' &&
			(platform.cost === null || platform.cost === 0) &&
			(platform.secondaryKPISpend === null || platform.secondaryKPISpend === 0)
		) {
			return 0;
		}
		return calculateEffectiveCost(platform);
	}, [platform]);

	if (!Number.isFinite(effectiveCost) || effectiveCost === 0) {
		return null;
	}
	const handleConfirm = async () => {
		if (
			effectiveCost !== undefined &&
			effectiveCost !== platform.secondaryKPIEffectiveCost
		) {
			const res = await updateReportPlatform({
				variables: {
					id: platform.id,
					data: {
						secondaryKPIEffectiveCost:
							Math.round(effectiveCost * 10000) / 10000,
					},
				},
			});
			if (res.errors) {
				message.error('Error while applying effective cost');
			}
			refetch();
		}
	};

	return (
		<Tooltip title="Calculate effective cost">
			<Popconfirm
				okButtonProps={{ loading }}
				onConfirm={handleConfirm}
				title="Apply effective cost"
				description={`The effective cost applied will be ${getFormattedNumber(
					effectiveCost,
					4
				)} ${currency}`}>
				<Button icon={<CalculatorOutlined />} size="small" />
			</Popconfirm>
		</Tooltip>
	);
};

export default EffectiveCostCalculate;
