// const capitalize = (string: string) => {
// 	return string.charAt(0).toUpperCase() + string.slice(1);
// };

import dayjs, { type Dayjs } from 'dayjs';

const capitalize = (str: string | undefined) => {
	if (!str) return '';
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const separateCamelCase = (string: string) => {
	return capitalize(string).replace(/([a-z])([A-Z])/g, '$1 $2');
};

const stringToColour = function (str: string) {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	let colour = '#';
	for (let i = 0; i < 3; i++) {
		let value = (hash >> (i * 8)) & 0xff;
		colour += ('00' + value.toString(16)).substr(-2);
	}
	return colour;
};

const getRowColor = (status: string, index: number) => {
	switch (status) {
		case 'bad':
			return 'bg-red-300';
		case 'good':
			return 'bg-green-300';
		case 'regular':
			return 'bg-orange-300';
		case 'none':
			return index % 2 === 0 ? 'bg-white' : 'bg-gray-200';
		default:
			return index % 2 === 0 ? 'bg-white' : 'bg-gray-200';
	}
};

const getPercentageColor = (percentage: number) => {
	if (percentage < 85 || percentage > 110) {
		return 'red';
	} else if (percentage >= 85 && percentage < 95) {
		return 'orange';
	} else {
		return 'green';
	}
};

const generateGIDPrefix = () => {
	const date = new Date();
	const year = date.getFullYear();
	const month =
		date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
	const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

	return `GID${year}${month}${day}`;
};

const calculateQuarters = (startDate: Dayjs, endDate: Dayjs) => {
	const start = dayjs(startDate);
	const end = dayjs(endDate);

	const quarters = [];

	const startQuarter = start.quarter();
	const endQuarter = end.quarter();

	if (start.year() === end.year()) {
		for (let i = startQuarter; i <= endQuarter; i++) {
			quarters.push(`Q${i}-${start.year()}`);
		}
	} else if (start.year() < end.year()) {
		for (let i = startQuarter; i <= 4; i++) {
			quarters.push(`Q${i}-${start.year()}`);
		}

		for (let i = 1; i <= endQuarter; i++) {
			quarters.push(`Q${i}-${end.year()}`);
		}
	}
	return quarters;
};

const calculateTotal = (items: number[]) => items.reduce((a, b) => a + b, 0);

const calculateAverage = (items: number[]) => {
	return calculateTotal(items) / items.length || 0;
};

const round2Decimals = (n: number) => {
	return Math.round((n + Number.EPSILON) * 100000) / 100000;
};

const getFormattedNumber = (val: number, decimals = 2) => {
	if (Number.isInteger(val)) {
		return new Intl.NumberFormat('en-US', {
			maximumSignificantDigits: round(val, decimals).toString().length,
		}).format(val);
	} else {
		return new Intl.NumberFormat('en-US', {
			maximumSignificantDigits: round(val, decimals).toString().length,
		}).format(round(val, decimals));
	}
};

const round = (value: number, decimals: number) => {
	const n = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
	return parseFloat(n.toFixed(decimals));
};

const isStaging = () => {
	return window.location.href.includes('staging');
};

const isOneOf = (checker: string, items: string[]) => {
	return items.includes(checker);
};

const getObjectiveType = (type: string) => {
	switch (type) {
		case 'CPM':
			return 'Impressions';
		case 'CPC':
			return 'Clicks';
		case 'CPV':
			return 'Views';
		case 'CPE':
			return 'Engagement';
		case 'CPL':
			return 'Leads';
		case 'CPA':
			return 'Conversions';
		case 'CPI':
			return 'Installs';
		case 'CPD':
			return 'Downloads';
		case 'CPR':
			return 'Reach';
		default:
			return null;
	}
};

export {
	capitalize,
	stringToColour,
	getRowColor,
	generateGIDPrefix,
	calculateQuarters,
	getPercentageColor,
	separateCamelCase,
	round2Decimals,
	getFormattedNumber,
	calculateTotal,
	calculateAverage,
	isOneOf,
	isStaging,
	getObjectiveType,
};
