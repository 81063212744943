type Props = {
	oldRows: {
		startDate: string;
		endDate: string;
		id: string;
		cost: number;
		monthlyBilling: number;
		reconciled: boolean;
		billed: boolean;
		comment: string;
	}[];
	newRows: {
		startDate: string;
		endDate: string;
		id: string;
		cost: number;
		monthlyBilling: number;
		reconciled: boolean;
		billed: boolean;
		comment: string;
	}[];
	isNew: boolean;
};

const highlightClassname = 'bg-yellow-300 py-0.5 rounded-sm';

const FinancialRowAudit = ({ oldRows, newRows, isNew }: Props) => {
	const rows = isNew ? newRows : oldRows;
	return (
		<div className="max-h-72 overflow-y-auto">
			{rows.map((row) => {
				const otherRow = isNew
					? oldRows.find((r) => r.id === row.id)
					: newRows.find((r) => r.id === row.id);
				return (
					<div
						key={row.id}
						className="my-2 rounded bg-gray-100 px-1 py-2 shadow">
						<code className="text-xs">
							<p
								className={`my-0 ${
									row.startDate !== otherRow?.startDate
										? highlightClassname
										: ''
								}`}>
								Start Date: {row.startDate}
							</p>
							<p
								className={`my-0 ${
									row.endDate !== otherRow?.endDate ? highlightClassname : ''
								}`}>
								End Date: {row.endDate}
							</p>
							<p
								className={`my-0 ${
									row.cost !== otherRow?.cost ? highlightClassname : ''
								}`}>
								Cost: {row.cost}
							</p>
							<p
								className={`my-0 ${
									row.monthlyBilling !== otherRow?.monthlyBilling
										? highlightClassname
										: ''
								}`}>
								Monthly Billing: {row.monthlyBilling}
							</p>
							{row.reconciled !== undefined &&
								otherRow?.reconciled !== undefined && (
									<p
										className={`my-0 ${
											row.reconciled !== otherRow?.reconciled
												? highlightClassname
												: ''
										}`}>
										Reconciled: {`${row.reconciled}`}
									</p>
								)}
							{row.billed !== undefined && otherRow?.billed !== undefined && (
								<p
									className={`my-0 ${
										row.billed !== otherRow?.billed ? highlightClassname : ''
									}`}>
									Billed: {`${row.billed}`}
								</p>
							)}
							<p
								className={`my-0 ${
									row.monthlyBilling !== otherRow?.monthlyBilling
										? highlightClassname
										: ''
								}`}>
								Comment: {row.comment}
							</p>
						</code>
					</div>
				);
			})}
		</div>
	);
};

export default FinancialRowAudit;
