enum BUYING_TYPES {
	CPM = 'CPM',
	CPC = 'CPC',
	CPV = 'CPV',
	CPE = 'CPE',
	CPL = 'CPL',
	CPA = 'CPA',
	CPI = 'CPI',
	CPD = 'CPD',
	CPR = 'CPR',
}

enum SECONDARY_KPI_TYPES {
	Impressions = 'Impressions',
	Clicks = 'Clicks',
	Interactions = 'Interactions',
	Conversions = 'Conversions',
	Leads = 'Leads',
	Installs = 'Installs',
	Followers = 'Followers',
	Reach = 'Reach',
	Likes = 'Likes',
	Views = 'Views',
}

export { BUYING_TYPES, SECONDARY_KPI_TYPES };
