import EditableNumberInput from '@/components/EditableNumberInput';
import NumberInput from '@/components/NumberInput';
import { AuthContext } from '@/context/AuthContext';
import { Form } from 'antd';

import { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';

const DebugPage = () => {
	const [form] = Form.useForm();

	const { user } = useContext(AuthContext);

	const [numberValue, setNumberValue] = useState<number>(0);

	const handleSubmit = (values: any) => {
		console.log(values);
	};

	const numero = Form.useWatch('number', form);

	return user?.permission?.seeDeveloperPages === 'yes' ? (
		<div>
			<Form form={form} onFinish={handleSubmit}>
				<NumberInput formProps={{ name: 'number', label: 'Number' }} />
				<p>{numero}</p>
				<EditableNumberInput
					inputValue={numberValue}
					handleChange={setNumberValue}
				/>
			</Form>
		</div>
	) : (
		<Navigate to="/" />
	);
};

export default DebugPage;
