import { getFormattedNumber } from '@/lib/utils';
import { CheckOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import { useState } from 'react';

type Props = {
	isForm?: boolean;
	inputProps?: React.ComponentProps<typeof InputNumber>;
	inputValue: number;
	handleChange?: (value: any) => void;
	handleConfirm?: () => void;
};

const EditableNumberInput = ({
	isForm,
	inputProps,
	inputValue,
	handleChange,
	handleConfirm,
}: Props) => {
	const [show, setShow] = useState(false);
	const inputParser = (value: any) => {
		let val = value as string;
		if (val.includes('.') && val.includes(',')) {
			val = val.replaceAll(',', '');
		} else if (val.includes(',') && !val.includes('.')) {
			val =
				val.split(',').length === 2
					? val.replace(',', '.')
					: val.replaceAll(',', '');
		}
		return val;
	};

	const handlePaste = (event: any) => {
		let pastedValue = event.clipboardData.getData('text');
		const value = pastedValue.trim();
		if (handleChange) {
			handleChange(parseFloat(value.replace(/,/g, '')));
		}
	};

	return (
		<>
			<p
				style={{
					display: show ? 'none' : 'block',
				}}
				className="my-0 mx-0 cursor-pointer py-1 px-[11px] text-sm font-medium text-blue-500 hover:text-blue-400 hover:underline"
				onClick={() => setShow(true)}>
				{inputProps?.prefix}{' '}
				{Number.isFinite(inputValue)
					? `${getFormattedNumber(inputValue)}`
					: '-'}
			</p>
			{show && (
				<InputNumber
					autoFocus
					controls={false}
					onPaste={handlePaste}
					onBlur={() => {
						if (handleConfirm) {
							handleConfirm();
						}
						setShow(false);
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							if (handleConfirm) {
								handleConfirm();
							}
							e.preventDefault();
							setShow(false);
						}
					}}
					className="w-full"
					parser={inputParser}
					{...(!isForm ? { value: inputValue } : {})}
					onChange={handleChange}
					{...inputProps}
				/>
			)}
		</>
	);
};

export default EditableNumberInput;
