import React, { useState } from 'react';
import { Button } from 'antd';
import { formatMoney } from '@/utils/currency';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import XLSX from 'sheetjs-style';
import type {
	FinancialRow,
	Summary,
} from '@/routes/ReconciliationPage/ReconciliationPage';

interface ExportExcelButtonProps {
	data: any;
	refetch: any;
	summary: Summary;
}

const ExportExcelButton: React.FC<ExportExcelButtonProps> = ({
	data,
	summary,
}) => {
	const [loading, setLoading] = useState(false);

	if (!summary) {
		return null;
	}

	const exportToExcel = async () => {
		setLoading(true);

		if (data) {
			const excelData = data.map((item: FinancialRow) => ({
				Agency: item.agency || '',
				Client: item.client || '',
				Campaign: item.campaign || '',
				Subcampaign: item.platformName || '',
				'Date Period':
					item.subcampaignStartDate && item.subcampaignEndDate
						? `${item.subcampaignStartDate} - ${item.subcampaignEndDate}`
						: '',
				'Order Number': item.orderNumber || '',
				Product: item.product
					? item.product.map((product: any) => product).join(', ')
					: '',
				Platform: item.platform || '',
				Formats: item.formats
					? item.formats.map((format: any) => format).join(', ')
					: '',
				'Business Model': item.businessModel
					? item.businessModel
							.map((businessModel: any) => businessModel)
							.join(', ')
					: '',
				'Buying Type & Rate': item.buyingType
					? `${item.buyingType} (${item.rate || ''})`
					: 'N/A',
				'On TVL': item.onTvl ? 'Yes' : 'No',
				Investment: formatMoney(item.investment, 'en-US', item.currency) || '',
				'Monthly Billing':
					formatMoney(item.monthlyBilling, 'en-US', item.currency) || '',
				Cost: formatMoney(item.cost, 'en-US', item.currency) || '',
				Reconciled: item.reconciled ? 'Done' : 'Pending',
				Comment: item.comment || '',
			}));

			const totalsRow = {
				Agency: '',
				Client: '',
				Campaign: '',
				Subcampaign: '',
				'Date Period': '',
				'Order Number': '',
				Product: '',
				Platform: '',
				Formats: '',
				'Business Model': '',
				'Buying Type & Rate': '',
				'On TVL': 'Total:',
				Investment: '$' + summary.totalInvestment,
				'Monthly Billing': '$' + summary?.totalMonthlyBilling,
				Cost: '$' + summary?.totalCost,
				Reconciled: '',
				Comment: '',
			};

			const allRows = [...excelData, totalsRow];

			const worksheet = XLSX.utils.json_to_sheet(allRows);

			const columnWidths = [
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 40 },
				{ wch: 40 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 10 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 40 },
			];

			worksheet['!cols'] = columnWidths;

			const rowHeights = allRows.map(() => ({ hpx: 25 }));
			worksheet['!rows'] = rowHeights;

			const headerCellStyle = {
				font: {
					name: 'Arial',
					sz: 13,
					bold: true,
					color: { rgb: '000000' },
				},
				fill: {
					fgColor: { rgb: 'DFDFDF' },
				},
				border: {
					top: { style: 'thin', color: { rgb: 'DFDFDF' } },
					bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
					left: { style: 'thin', color: { rgb: 'DFDFDF' } },
					right: { style: 'thin', color: { rgb: 'DFDFDF' } },
				},
				alignment: { horizontal: 'center', vertical: 'center' },
			};

			const columnCount =
				excelData.length > 0 ? Object.keys(excelData[0]).length : 0;
			const columnRange = Array.from({ length: columnCount }, (_, index) =>
				XLSX.utils.encode_cell({ r: 0, c: index })
			);

			columnRange.forEach((cell) => {
				worksheet[cell].s = headerCellStyle;
			});

			const cellStyles = {
				blackRow: {
					font: {
						name: 'Arial',
						sz: 11,
						bold: false,
						color: { rgb: '000000' },
					},
					fill: {
						fgColor: { rgb: 'FFFFFF' },
					},
					border: {
						top: { style: 'thin', color: { rgb: 'DFDFDF' } },
						bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
						left: { style: 'thin', color: { rgb: 'DFDFDF' } },
						right: { style: 'thin', color: { rgb: 'DFDFDF' } },
					},
					alignment: { horizontal: 'center', vertical: 'center' },
				},
				whiteRow: {
					font: {
						name: 'Arial',
						sz: 11,
						bold: false,
						color: { rgb: '000000' },
					},
					fill: {
						fgColor: { rgb: 'DFDFDF' },
					},
					border: {
						top: { style: 'thin', color: { rgb: 'DFDFDF' } },
						bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
						left: { style: 'thin', color: { rgb: 'DFDFDF' } },
						right: { style: 'thin', color: { rgb: 'DFDFDF' } },
					},
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			};

			excelData.forEach((rowData: FinancialRow, rowIndex: number) => {
				const cellStyle =
					rowIndex % 2 === 0 ? cellStyles.blackRow : cellStyles.whiteRow;
				Object.keys(rowData).forEach((key, colIndex) => {
					const cellAddress = XLSX.utils.encode_cell({
						r: rowIndex + 1,
						c: colIndex,
					});
					worksheet[cellAddress].s = cellStyle;
				});
			});

			allRows.forEach((rowData: FinancialRow, rowIndex: number) => {
				const cellStyle =
					rowIndex % 2 === 0 ? cellStyles.blackRow : cellStyles.whiteRow;
				Object.keys(rowData).forEach((key, colIndex) => {
					const cellAddress = XLSX.utils.encode_cell({
						r: rowIndex + 1,
						c: colIndex,
					});
					worksheet[cellAddress].s = cellStyle;
				});
			});

			const lastRowIndex = allRows.length;
			const lastRowRange = Array.from({ length: columnCount }, (_, colIndex) =>
				XLSX.utils.encode_cell({ r: lastRowIndex, c: colIndex })
			);

			lastRowRange.forEach((cell) => {
				if (worksheet[cell]) {
					worksheet[cell].s = headerCellStyle;
				}
			});

			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Reconciliation');
			const currentDate = dayjs().format('YYYY-MM-DD');
			const fileName = `Reconciliation_${currentDate}.xlsx`;
			XLSX.writeFile(workbook, fileName);

			setLoading(false);
		}
	};

	return (
		<Button
			className="ml-1"
			type="primary"
			icon={<DownloadOutlined />}
			onClick={exportToExcel}
			loading={loading}>
			{loading ? 'Exporting .xlsx' : 'Download .xlsx'}
		</Button>
	);
};

export default ExportExcelButton;
