import dayjs from 'dayjs';
import { capitalize } from '@/lib/utils';
import { TableColumnsType } from 'antd';
import { Key } from 'react';
import { formatMoney } from '@/utils/currency';

interface Record {
	status: string;
	endDate: string;
}

export const checkCampaignStatus = (records: Record[]): string => {
	const campaignStatus = getCampaignStatus(records);
	if (campaignStatus) {
		return capitalize(campaignStatus);
	} else {
		return 'N/A';
	}
};

export const styleCampaignStatus = (records: Record[]): string => {
	const campaignStatus = getCampaignStatus(records);

	if (records.length <= 0) return 'bg-white text-[12px]';

	if (campaignStatus === 'active') {
		return 'bg-lime-100 text-[12px]';
	} else if (campaignStatus === 'implemented') {
		return 'bg-violet-200 text-[12px]';
	} else if (campaignStatus === 'pending') {
		return 'bg-blue-100 text-[12px]';
	} else if (campaignStatus === 'paused') {
		return 'bg-red-200 text-[12px]';
	} else if (campaignStatus === 'finished') {
		return 'bg-gray-200 text-[12px]';
	} else {
		return 'bg-yellow-200 text-[12px]';
	}
};

export const setWarning = (record: Record): boolean => {
	const endDate = dayjs(record.endDate);
	const status = checkCampaignStatus([record]);

	if (status === 'N/A') return false;

	if (endDate.isBefore(dayjs()) && status !== 'Finished') {
		return true;
	} else {
		return false;
	}
};

const getCampaignStatus = (records: Record[]): string => {
	const isActive = records.some((record) => record.status === 'active');
	let status;

	if (isActive) {
		status = 'active';
	} else {
		status = checkStatus(records);
	}

	return status;
};

const checkStatus = (records: Record[]): string => {
	if (records.length === 0) return 'N/A';

	const statuses = new Set(records.map((record) => record.status));

	if (statuses.size === 1) {
		return Array.from(statuses)[0];
	} else if (statuses.has('active')) {
		return 'active';
	} else {
		return 'mixed';
	}
};

const calculateDailyObjective = (
	start: any,
	record: any,
	campaignDays: number,
	activeDays: number
) => {
	const dailyObjective = record.objectiveValue / record.platformDays;

	return dailyObjective;
};

const calculateSpend = (record: any) => {
	if (record.objectiveType === 'absolute') {
		if (record.buyingType === 'CPM' || record.buyingType === 'CPR') {
			return (record.objectiveSpend * record.rate) / 1000;
		} else {
			return record.objectiveSpend * record.rate;
		}
	}
	return record.objectiveSpend;
};

const calculateDateDelivery = (
	record: any,
	campaignDays: number,
	activeDays: number,
	start: any
) => {
	const dailyObjective =
		record.objectiveType === 'absolute'
			? calculateDailyObjective(start, record, campaignDays, activeDays) *
			  record.rate
			: calculateDailyObjective(start, record, campaignDays, activeDays);
	const spend = calculateSpend(record);
	let dateDelivery: any = '';
	if (!isNaN(dailyObjective) && dailyObjective !== 0 && activeDays !== 0) {
		dateDelivery = spend / (dailyObjective * activeDays);
	} else {
		dateDelivery = 'N/A';
	}

	return dateDelivery;
};

const calculateTotalDateDelivery = (record: any): number => {
	const start = dayjs(record.startDate);
	const end = dayjs(record.endDate);
	const now = dayjs();
	const campaignDays = end.diff(start, 'days');
	const activeDays = now.diff(start, 'days');
	//TODO REFACTOR INTO SINGLE REDUCE
	const totalSpend = record.platforms.reduce(
		(prev: any, current: any) => prev + calculateSpend(current),
		0
	);
	const totalObjective = record.platforms.reduce(
		(prev: number, current: any) =>
			prev +
			convertToMoney(
				current.objectiveValue,
				current.buyingType,
				current.objectiveType,
				current.rate
			),
		0
	);
	const totalDaily = record.platforms
		.map((platform: any) => {
			const {
				objectiveValue,
				objectiveSpend,
				buyingType,
				rate,
				objectiveType,
			} = platform;
			const dailyObjective =
				(objectiveValue - objectiveSpend) / (campaignDays - activeDays);
			return convertToMoney(dailyObjective, buyingType, objectiveType, rate);
		})
		.reduce((prev: number, current: number) => prev + current, 0);

	let totalDateDelivery: any = '';
	if (totalObjective != 0 && activeDays != 0) {
		totalDateDelivery = totalSpend / (totalObjective * activeDays);
	} else {
		totalDateDelivery = 'N/A';
	}

	return totalDateDelivery;
};

const convertToMoney = (
	value: number,
	buyingType: string,
	objectiveType: string,
	rate: number
) => {
	let moneyValue;
	if (objectiveType === 'budget') {
		moneyValue = value;
	} else if (objectiveType === 'absolute' && buyingType === 'CPM') {
		moneyValue = (value * rate) / 1000;
	} else {
		moneyValue = value * rate;
	}
	return moneyValue;
};

const checkIfActive = (startDate: string, endDate: string) => {
	if (
		dayjs().isAfter(dayjs(endDate, 'YYYY-MM-DD')) ||
		dayjs().isBefore(dayjs(startDate, 'YYYY-MM-DD'))
	) {
		return false;
	}
	return true;
};

const checkIfActiveDays = (activeDays: number) => {
	if (activeDays > 0) {
		return true;
	} else {
		return false;
	}
};

const calculateCampaignBudget = (platforms: any[], campaignBudget: number) => {
	let totalBudget = 0;

	if (platforms.length > 0) {
		totalBudget = platforms.reduce((prev, current) => {
			return prev + current.budget;
		}, 0);
	} else {
		totalBudget = campaignBudget;
	}

	return totalBudget;
};

const getDefaultColumns = () => {
	return [
		'campaign',
		// 'market',
		'country',
		'client',
		'accountManager',
		'planner',
		'status',
		'startDate',
		'endDate',
		'campaignDays',
		'sumBudget',
		'budgetLC',
		'spend',
		'totalDelivery',
		'dateDelivery',
		'actions',
	];
};

const getTableColumns = (
	filterColumns: any,
	tableColumns: TableColumnsType<any>,
	isSelect: boolean
) => {
	const filterTableColumns: TableColumnsType<any> = [];
	if (!isSelect) {
		filterColumns.map((column: any, i: number) =>
			tableColumns.forEach((col, index) => {
				if (col.key === column) {
					filterTableColumns.push(col);
				}
			})
		);
	} else {
		const orderedColumns = getOrderedTable(filterColumns, tableColumns);
		orderedColumns.map((c: any) => filterTableColumns.push(c));
	}

	return filterTableColumns;
};

// INICIO
const getSubCampaignTableColumns = (
	filterSubCampaignColumns: any,
	column: TableColumnsType<any>,
	isSelect: boolean
) => {
	const filterTableColumns: TableColumnsType<any> = [];
	if (!isSelect) {
		filterSubCampaignColumns.map((column: any, i: number) =>
			column.forEach((col: any, index: number) => {
				if (col.key === column) {
					filterTableColumns.push(col);
				}
			})
		);
	} else {
		const orderedColumns = getOrderedSubCampaignTable(
			filterSubCampaignColumns,
			column
		) as TableColumnsType<any>[];
		orderedColumns.map((c: any) => filterSubCampaignColumns.push(c));
	}

	return filterSubCampaignColumns;
};

// NUEVO INICIO
const getOrderedSubCampaignTable = (
	filterSubCampaignColumns: any[],
	subCampaignColumns: TableColumnsType<any>
) => {
	let columnsToDelete: (Key | undefined)[] = [];
	subCampaignColumns.forEach((col) => columnsToDelete.push(col.key));

	for (let i = 0; i < filterSubCampaignColumns.length; i++) {
		const arrlen = columnsToDelete.length;
		for (let j = 0; j < arrlen; j++) {
			if (filterSubCampaignColumns[i] === columnsToDelete[j]) {
				columnsToDelete = columnsToDelete
					.slice(0, j)
					.concat(columnsToDelete.slice(j + 1, arrlen));
			}
		}
	}

	// return getOrderedSubCampaignTable(columnsToDelete, subCampaignColumns);
	return getOrderedFilterSubCampaignTable(columnsToDelete, subCampaignColumns);
};

const getOrderedFilterSubCampaignTable = (
	columnsToDelete: any[],
	subCampaignColumns: TableColumnsType<any>
) => {
	let newTableColumns = [...subCampaignColumns];
	for (let i = 0; i < newTableColumns.length; i++) {
		const arrlen = columnsToDelete.length;
		for (let j = 0; j < arrlen; j++) {
			if (newTableColumns[i].key === columnsToDelete[j]) {
				newTableColumns.splice(i, 1);
				i--;
				break;
			}
		}
	}

	return newTableColumns;
};

// FIN

const getOrderedTable = (
	filterColumns: any,
	tableColumns: TableColumnsType<any>
) => {
	let columnsToDelete: (Key | undefined)[] = [];
	tableColumns.map((col) => columnsToDelete.push(col.key));

	for (var i = 0; i < filterColumns.length; i++) {
		var arrlen = columnsToDelete.length;
		for (var j = 0; j < arrlen; j++) {
			if (filterColumns[i] == columnsToDelete[j]) {
				columnsToDelete = columnsToDelete
					.slice(0, j)
					.concat(columnsToDelete.slice(j + 1, arrlen));
			}
		}
	}

	return getOrderedFilterTable(columnsToDelete, tableColumns);
};

const getOrderedFilterTable = (
	columnsToDelete: any,
	tableColumns: TableColumnsType<any>
) => {
	let newTableColumns = tableColumns;
	for (var i = 0; i < newTableColumns.length; i++) {
		var arrlen = columnsToDelete.length;
		for (var j = 0; j < arrlen; j++) {
			if (newTableColumns[i].key == columnsToDelete[j]) {
				let index = newTableColumns.indexOf(newTableColumns[i]);
				newTableColumns.splice(index, 1);
			}
		}
	}

	return newTableColumns;
};

const getSelectItems = () => {
	return [
		{
			value: 'gluCampaignID',
			label: 'GID',
		},
		{
			value: 'campaign',
			label: 'Campaign',
		},
		{
			value: 'agency',
			label: 'Agency',
		},
		{
			value: 'country',
			label: 'Country',
		},
		{
			value: 'market',
			label: 'Market',
		},
		{
			value: 'client',
			label: 'Client',
		},
		{
			value: 'accountManager',
			label: 'Account Manager',
		},
		{
			value: 'planner',
			label: 'Planner',
		},
		{
			value: 'status',
			label: 'Status',
		},
		{
			value: 'startDate',
			label: 'Start Date',
		},
		{
			value: 'endDate',
			label: 'End Date',
		},
		{
			value: 'campaignDays',
			label: 'Campaign days',
		},
		{
			value: 'activeDays',
			label: 'Active Days',
		},
		{
			value: 'sumBudget',
			label: 'Sum Budget',
		},
		{
			value: 'budgetLC',
			label: 'Budget',
		},
		{
			value: 'investment',
			label: 'Investment',
		},
		{
			value: 'spend',
			label: 'Spend',
		},
		{
			value: 'objectiveCost',
			label: 'Benchmark Cost',
		},
		{
			value: 'totalDelivery',
			label: 'T Delivery',
		},
		// {
		//   value: 'dateDelivery',
		//   label: 'D Delivery',
		// },
		{
			value: 'actions',
			label: 'Actions',
		},
	];
};

function formatCurrencyAmount(value: number, campaignCurrency: string) {
	let formattedAmount = formatMoney(value, 'en-US', campaignCurrency);

	if (campaignCurrency === 'MXN') {
		formattedAmount = formattedAmount.replace('MX$', 'MXN ');
	} else if (campaignCurrency === 'USD') {
		formattedAmount = formattedAmount.replace('$', 'USD ');
	}

	return formattedAmount;
}

const getSubCampaignDefaultColumns = () => {
	return [
		'name',
		'platform',
		'formats',
		'status',
		'dateDelivery',
		'totalDelivery',
		'startDate',
		'endDate',
		'averageDailyObjective',
	];
};

const getSubCampaignSelectItems = () => {
	return [
		{ label: 'Name', value: 'name' },
		{ label: 'Platform', value: 'platform' },
		{ label: 'Format', value: 'formats' },
		{ label: 'Status', value: 'status' },
		{ label: 'Date Delivery', value: 'dateDelivery' },
		{ label: 'Total Delivery', value: 'totalDelivery' },
		{ label: 'Start Date', value: 'startDate' },
		{ label: 'End Date', value: 'endDate' },
		{ label: 'Average Daily Objective', value: 'averageDailyObjective' },
		{ label: 'Daily Objective', value: 'dailyObjective' },
		{ label: 'Platform Days', value: 'platformDays' },
		{ label: 'Active Days', value: 'activeDays' },
		{ label: 'Objective', value: 'objective' },
		{ label: 'Objective Spend', value: 'objectiveSpend' },
		{ label: 'Spend', value: 'spend' },
		{ label: 'Rate', value: 'rate' },
		{ label: 'Effective Cost', value: 'effectiveCost' },
		{ label: 'Objective Optimization', value: 'objectiveOptimization' },
	];
};

const getOptimizationsColor = (value: number) => {
	if (value < 50) {
		return 'text-red-500';
	} else if (value < 60) {
		return 'text-orange-500';
	} else if (value < 70) {
		return 'text-yellow-500';
	} else return 'text-green-500';
};

export const HIDDEN_ACCOUNT_MANAGER = 'Juan Rebellato';

export {
	calculateSpend,
	calculateDateDelivery,
	calculateTotalDateDelivery,
	checkIfActive,
	checkIfActiveDays,
	calculateCampaignBudget,
	calculateDailyObjective,
	getDefaultColumns,
	getTableColumns,
	getSelectItems,
	formatCurrencyAmount,
	getSubCampaignSelectItems,
	getSubCampaignDefaultColumns,
	getSubCampaignTableColumns,
	getOptimizationsColor,
};
