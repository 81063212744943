import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { loader } from 'graphql.macro';

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { API_URL } from './lib/constants';
import { ConfigProvider } from 'antd';
const schema = loader('./schema.graphql');

const link = createHttpLink({
	uri: API_URL,
	credentials: 'include',
});

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link,
	typeDefs: schema,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<ApolloProvider client={client}>
		<ConfigProvider
			theme={{
				token: { colorPrimary: '#73b9f0', borderRadius: 6 },
				components: {
					Table: {
						rowHoverBg: '#EAEAEA',
					},
				},
			}}>
			<App />
		</ConfigProvider>
	</ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
