import { calculateTotal } from '@/lib/utils';
import type { FinancialRow } from '@/routes/ReconciliationPage/ReconciliationPage';

const getParsedValue = (v: string) => {
	if ((v.match(/,/g) || []).length >= 1 && (v.match(/\./g) || []).length <= 1) {
		//If pasted number is 123,456,789.15
		v = v.replace(/,/g, '');
	} else if ((v.match(/\./g) || []).length > 1) {
		//If pasted number is 123.456,15
		v = v.replace(/\./g, '').replace(',', '.');
	}

	const value = parseFloat(v);
	return value;
};

const getSummary = (currentRows: FinancialRow[] | readonly FinancialRow[]) => {
	if (!currentRows) {
		return;
	}
	const uniqueIds = [
		...new Set(currentRows.map((row) => row.id.split('--')[0])),
	];

	const uniqueCampaigns = uniqueIds
		.map((c) => currentRows.find((r) => r.id.includes(c)))
		.filter((c) => c !== undefined) as FinancialRow[];

	const currency = hasOnlyOneCurrency(uniqueCampaigns)
		? uniqueCampaigns[0].currency
		: null;

	const totals = {
		currency,
		totalInvestmentLocal: currency
			? calculateTotal(uniqueCampaigns.map((c: any) => c.investment))
			: null,
		totalInvestment: calculateTotal(
			uniqueCampaigns.map((c: any) => c.investment * c.exchangeRate)
		),
		totalCost: calculateTotal(currentRows.map((c) => c.cost * c.exchangeRate)),
		totalCostLocal: currency
			? calculateTotal(currentRows.map((c) => c.cost))
			: null,
		totalMonthlyBilling: calculateTotal(
			currentRows.map((c) => c.monthlyBilling * c.exchangeRate)
		),
		totalMonthlyBillingLocal: currency
			? calculateTotal(currentRows.map((c) => c.monthlyBilling))
			: null,
	};

	return totals;
};

const hasOnlyOneCurrency = (currentData: readonly FinancialRow[]) => {
	if (currentData) {
		const uniqueCurrencies = new Set(currentData.map((t) => t.currency));
		return uniqueCurrencies.size === 1;
	}
	return false;
};

export { getParsedValue, getSummary };
