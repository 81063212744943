import {
	ReportPlatformsData,
	ReportPlatformsVariables,
	REPORT_PLATFORMS_QUERY,
} from '@/lib/reportsApi';
import {
	capitalize,
	getFormattedNumber,
	getObjectiveType,
	getPercentageColor,
	separateCamelCase,
} from '@/lib/utils';
import { formatMoney } from '@/utils/currency';
import { useQuery } from '@apollo/client';
import { Table, TableColumnsType, Select, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import AddPlatformModal from './PlatformModal/AddPlatformModal';
import EditStatusField from './EditStatusField';
import SpendCell from './SpendCell';
import {
	calculateDateDelivery,
	calculateDailyObjective,
	getOptimizationsColor,
} from './utils';
import PlatformActions from '@/routes/ClientServicesPage/components/PlatformActions';

import { reportings } from '@/lib/constants';
import { yesNoPending } from '@/lib/constants';
import { useMutation } from '@apollo/client';
import { UPDATE_PLATFORM_MUTATION } from '@/lib/reportsApi';
import { message } from 'antd';
import CostEditModal from '@/routes/ClientServicesPage/components/CostEditModal';
import { CheckOutlined, CloseOutlined, DashOutlined } from '@ant-design/icons';
import type { FinancialRow } from '@/routes/ReconciliationPage/ReconciliationPage';
import type { Report } from '@/interfaces/Report';
import EffectiveCostCalculate from '@/routes/ClientServicesPage/components/EffectiveCostCalculate';

type Props = {
	platforms: any;
	startDate: any;
	endDate: any;
	campaignDays: number;
	activeDays: number;
	timePeriod: number;
	budget: number;
	campaignCurrency: string;
	id: string;
	filterSubCampaignColumns: any;
	report: Report;
	refetchReports: () => void;
};

const PlatformSubtable: FC<Props> = ({
	refetchReports,
	startDate,
	endDate,
	id,
	campaignDays,
	campaignCurrency,
	report,
}: Props) => {
	const { loading, data, refetch } = useQuery<
		ReportPlatformsData,
		ReportPlatformsVariables
	>(REPORT_PLATFORMS_QUERY, { variables: { report: id } });

	const [updateReportPlatform] = useMutation(UPDATE_PLATFORM_MUTATION);

	const handleReportingChange = async (value: string, record: any) => {
		const updatedData = {
			reporting: value,
		};

		try {
			const response = await updateReportPlatform({
				variables: { id: record.id, data: updatedData },
			});
			console.log('Respuesta de actualización:', response);

			message.success('Reporting updated.');

			refetch();
		} catch (error) {
			console.error('Error al actualizar la plataforma:', error);
			message.error('Error al actualizar el reporting. Intenta nuevamente.');
		}
	};

	const handleFinalReportChange = async (value: string, record: any) => {
		const updatedData = {
			finalReport: value,
		};

		try {
			const response = await updateReportPlatform({
				variables: { id: record.id, data: updatedData },
			});
			console.log('Respuesta de actualización:', response);

			message.success('Final Report updated.');

			refetch();
		} catch (error) {
			console.error('Error al actualizar la plataforma:', error);
			message.error('Error al actualizar el Final Report. Intenta nuevamente.');
		}
	};

	const handleIoOrPoChange = async (value: string, record: any) => {
		const updatedData = {
			ioOrPo: value,
		};

		try {
			const response = await updateReportPlatform({
				variables: { id: record.id, data: updatedData },
			});
			console.log('Respuesta de actualización:', response);

			message.success('Io or Po updated.');

			refetch();
		} catch (error) {
			console.error('Error al actualizar la plataforma:', error);
			message.error('Error al actualizar el Io or Po. Intenta nuevamente.');
		}
	};

	const handleScreenshotsChange = async (value: string, record: any) => {
		const updatedData = {
			screenshots: value,
		};

		try {
			const response = await updateReportPlatform({
				variables: { id: record.id, data: updatedData },
			});
			console.log('Respuesta de actualización:', response);

			message.success('Screenshots updated.');

			refetch();
		} catch (error) {
			console.error('Error al actualizar la plataforma:', error);
			message.error('Error al actualizar el Screenshots. Intenta nuevamente.');
		}
	};

	const names =
		data?.report?.platforms?.flatMap((platform: any) =>
			platform?.name ? [{ name: platform.name }] : []
		) || [];

	const dataSource = data?.report?.platforms || [];

	const formats = Array.from(
		new Set(
			data?.report?.platforms?.flatMap(
				(platform: any) =>
					platform.formats?.map((format: any) => format.name) || []
			) || []
		)
	).map((name) => ({ name }));

	const columns: TableColumnsType<any> = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			fixed: 'left',
			filters: names
				.slice()
				.sort((a, b) => a.name.localeCompare(b.name))
				.map(({ name }) => ({
					text: name,
					value: name,
				})),
			onFilter: (value, record) => record.name === value,
			sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
			render: (name) => name || 'N/A',
			filterSearch: true,
		},
		{
			title: 'Platform',
			dataIndex: 'platform',
			key: 'platform',
			width: 120,
			fixed: 'left',
			filters: [
				...Array.from(
					new Set(
						dataSource.map((item) => item.platform?.name).filter((name) => name)
					)
				).map((platformName) => ({
					text: platformName,
					value: platformName,
				})),
				{ text: 'N/A', value: 'N/A' },
			],
			onFilter: (value, record) =>
				value === 'N/A'
					? !record.platform?.name
					: record.platform?.name === value,
			sorter: (a, b) => {
				const nameA = a.platform?.name || 'N/A';
				const nameB = b.platform?.name || 'N/A';
				return nameA.localeCompare(nameB);
			},
			render: (platform) => platform?.name || 'N/A',
			filterSearch: true,
		},
		{
			title: 'Format',
			dataIndex: 'formats',
			key: 'formats',
			width: 130,
			fixed: 'left',
			filters: [
				...formats
					.slice()
					.sort((a, b) => a.name.localeCompare(b.name))
					.map(({ name }) => ({
						text: name,
						value: name,
					})),
				{ text: 'N/A', value: 'N/A' },
			],
			onFilter: (value, record) => {
				if (value === 'N/A') {
					return (record.formats || []).length === 0;
				}
				return (record.formats || []).some(
					(format: any) => format.name === value
				);
			},
			sorter: (a, b) => {
				const formatsA =
					(a.formats || []).map((format: any) => format.name).join(', ') ||
					'N/A';
				const formatsB =
					(b.formats || []).map((format: any) => format.name).join(', ') ||
					'N/A';

				if (formatsA === 'N/A' && formatsB !== 'N/A') return -1;
				if (formatsB === 'N/A' && formatsA !== 'N/A') return 1;
				return formatsA.localeCompare(formatsB);
			},
			render: (formats) =>
				(formats || []).map((format: any) => format.name).join(', ') || 'N/A',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 150,
			fixed: 'left',
			filters: Array.from(
				new Set(dataSource.map((record) => record.status))
			).map((status) => ({
				text: capitalize(status),
				value: status,
			})),
			onFilter: (value, record) => record.status === value,
			sorter: (a, b) => a.status.localeCompare(b.status),
			render: (status, record) => (
				<EditStatusField
					currentStatus={status}
					platformId={record.id}
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Date delivery',
			dataIndex: 'objectiveSpend',
			key: 'dateDelivery',
			align: 'center',
			render: (platforms: any[], record) => {
				const start = dayjs(startDate);
				const end = dayjs(endDate);

				let content;
				let color;

				const dateDelivery = calculateDateDelivery(
					record,
					campaignDays,
					record.activeDays,
					start
				);

				if (isNaN(dateDelivery) || !isFinite(dateDelivery)) {
					return 'N/A';
				} else {
					if (record.objectiveType === 'absolute') {
						const objective = record.objectiveValue || 0;
						const objectiveSpend = record.objectiveSpend || 0;
						const platformDays = record.platformDays || 0;
						const activeDays = record.activeDays || 0;

						const dailyObjective = objective / platformDays;

						const formula = objectiveSpend / (dailyObjective * activeDays);

						const result = Math.round(formula * 10000) / 100;

						if (dayjs().isBefore(start)) {
							content = 'Not started';
							color = `border border-solid border-green-500 text-green-500`;
						} else if (dayjs().isAfter(end)) {
							color = `bg-${getPercentageColor(result)}-500 text-white`;
							content = `${result}%`;
						} else {
							color = `bg-${getPercentageColor(result)}-500 text-white`;
							content = `${result}%`;
						}
					} else {
						const result = Math.round(dateDelivery * 10000) / 100;

						color = `bg-${getPercentageColor(result)}-500 text-white`;
						content = `${result}%`;

						if (dayjs().isBefore(start)) {
							content = 'Not started';
							color = `border border-solid border-green-500 text-green-500`;
						} else if (dayjs().isAfter(end)) {
							color = `bg-${getPercentageColor(result)}-500 text-white`;
							content = `${result}%`;
						} else {
							color = `bg-${getPercentageColor(result)}-500 text-white`;
							content = `${result}%`;
						}
					}

					return (
						<span
							className={`block  w-full rounded-md px-2 py-1 font-medium ${color}`}>
							{content}
						</span>
					);
				}
			},
			width: 130,
			fixed: 'left',
			sorter: (a, b) => {
				const dateDeliveryA = calculateDateDelivery(
					a,
					campaignDays,
					a.activeDays,
					dayjs(startDate)
				);
				const dateDeliveryB = calculateDateDelivery(
					b,
					campaignDays,
					b.activeDays,
					dayjs(startDate)
				);

				if (a.objectiveType === 'absolute' && b.objectiveType === 'absolute') {
					const objectiveSpendA = a.objectiveSpend || 0;
					const objectiveSpendB = b.objectiveSpend || 0;

					const dailyObjectiveA = a.objectiveValue / (a.platformDays || 1);
					const dailyObjectiveB = b.objectiveValue / (b.platformDays || 1);

					const resultA =
						objectiveSpendA / (dailyObjectiveA * (a.activeDays || 1));
					const resultB =
						objectiveSpendB / (dailyObjectiveB * (b.activeDays || 1));

					return resultA - resultB;
				}

				return dateDeliveryA - dateDeliveryB;
			},
		},
		{
			title: 'Total Delivery',
			dataIndex: 'objectiveSpend',
			key: 'totalDelivery',
			fixed: 'left',
			width: 130,
			sorter: (a, b) => {
				const deliveryA = a.objectiveValue
					? a.objectiveSpend / a.objectiveValue
					: 0;
				const deliveryB = b.objectiveValue
					? b.objectiveSpend / b.objectiveValue
					: 0;
				return deliveryA - deliveryB;
			},
			render: (spend, record) => {
				if (record.objectiveValue === null || record.objectiveValue === 0) {
					return 'N/A';
				} else {
					const delivery = spend / record.objectiveValue;
					return `${Math.round(delivery * 100)}%`;
				}
			},
		},

		{
			key: 'startDate',
			dataIndex: 'startDate',
			title: 'Start Date',
			width: 120,
			render: (date) => dayjs(date).format('MMM DD, YYYY'),
			sorter: (a, b) => dayjs(a.startDate).unix() - dayjs(b.startDate).unix(),
		},
		{
			key: 'endDate',
			dataIndex: 'endDate',
			title: 'End Date',
			width: 120,
			render: (date) => dayjs(date).format('MMM DD, YYYY'),
			sorter: (a, b) => dayjs(a.endDate).unix() - dayjs(b.endDate).unix(),
		},
		{
			title: 'Average Daily Objective',
			dataIndex: 'averageDailyObjective',
			key: 'averageDailyObjective',
			width: 180,
			render: (spend, record) => {
				const start = dayjs(record.startDate);
				const end = dayjs(record.endDate).add(1, 'day');
				const campaignDays = end.diff(start, 'days');
				const activeDays = dayjs().diff(start, 'days');
				if (dayjs().isAfter(end)) {
					return 'Finished Campaign';
				}
				const dailyObjective = calculateDailyObjective(
					start,
					record,
					campaignDays,
					activeDays
				);

				if (record.platformDays === null || record.platformDays === 0) {
					return 'N/A';
				} else {
					if (record.objectiveType === 'absolute') {
						return Intl.NumberFormat('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(Math.round(dailyObjective));
					} else {
						const formattedAmount = formatMoney(
							Math.round(dailyObjective * 100) / 100
						);

						const amountWithoutCurrencySymbol = formattedAmount
							.replace(/^\$\s*/, '')
							.trim();

						return `${campaignCurrency} ${amountWithoutCurrencySymbol}`;
					}
				}
			},
		},
		{
			title: 'Daily Objective',
			dataIndex: 'objectiveSpend',
			key: 'dailyObjective',
			width: 120,
			render: (value, record) => {
				const objective = record.objectiveValue || 0;
				const objectiveSpend = record.objectiveSpend || 0;
				const platformDays = record.platformDays || 0;
				const activeDays = record.activeDays || 0;

				const objectiveType =
					record.objectiveType === 'absolute'
						? getObjectiveType(record.buyingType)
						: null;

				if (
					platformDays === 0 ||
					activeDays === 0 ||
					isNaN(objective) ||
					isNaN(objectiveSpend) ||
					isNaN(platformDays) ||
					isNaN(activeDays)
				) {
					return 'N/A';
				}

				const averageDailyObjective =
					(objective - objectiveSpend) / (platformDays - activeDays);
				if (isNaN(averageDailyObjective) || !isFinite(averageDailyObjective)) {
					return 'N/A';
				}

				return (
					<div className="flex flex-col">
						<span>
							{averageDailyObjective.toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</span>
						{objectiveType && <span>({objectiveType})</span>}
					</div>
				);
			},
		},
		{
			title: 'Days',
			dataIndex: 'platformDays',
			key: 'platformDays',
			width: 90,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Days updated."
					updateField="platformDays"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Active days',
			dataIndex: 'activeDays',
			key: 'activeDays',
			width: 105,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Active days updated."
					updateField="activeDays"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Objective',
			dataIndex: 'objectiveValue',
			key: 'objective',
			width: 105,
			render: (objective, record) => {
				if (!objective) return `${campaignCurrency} 0`;

				const formattedObjective = new Intl.NumberFormat().format(objective);

				const objectiveType =
					record.objectiveType === 'absolute'
						? getObjectiveType(record.buyingType)
						: null;

				const value =
					record.objectiveType === 'budget'
						? `${campaignCurrency} ${formattedObjective}`
						: `${formattedObjective}`;

				return (
					<div className="flex flex-col">
						<span>{value}</span>
						{objectiveType && <span>({objectiveType})</span>}
					</div>
				);
			},
		},
		{
			title: 'Objective Spend',
			dataIndex: 'objectiveSpend',
			key: 'objectiveSpend',
			width: 130,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Objective Spend updated."
					refetch={refetch}
					updateField="objectiveSpend"
					prefix={record.objectiveType === 'budget' ? campaignCurrency : ''}
				/>
			),
		},
		{
			title: 'Spend',
			dataIndex: 'objectiveSpend',
			key: 'status',
			width: 120,
			render: (value, record) => {
				const rate = record.rate || 1;
				let formattedAmount;

				if (record.objectiveType === 'absolute') {
					if (record.buyingType === 'CPM' || record.buyingType === 'CPR') {
						const result = (value * rate) / 1000;
						formattedAmount = isNaN(result)
							? '0.00'
							: `${campaignCurrency} ${getFormattedNumber(result)}`;
					} else {
						const result = value * rate;
						formattedAmount = isNaN(result)
							? '0.00'
							: `${campaignCurrency} ${getFormattedNumber(result)}`;
					}
				}

				if (record.objectiveType === 'budget') {
					const amount = value || 0;
					formattedAmount = `${campaignCurrency} ${getFormattedNumber(amount)}`;
				}

				return formattedAmount;
			},
		},

		{
			title: 'Rate',
			dataIndex: 'rate',
			key: 'rate',
			width: 120,
			render: (rate, record) => {
				if (!rate || isNaN(rate)) {
					return 'N/A for objective';
				}

				if (record.objectiveType === 'absolute') {
					return `${campaignCurrency} ${rate}`;
				} else {
					return 'N/A for objective';
				}
			},
		},
		{
			title: 'Effective Cost',
			dataIndex: 'secondaryKPIEffectiveCost',
			key: 'secondaryKPIEffectiveCost',
			width: 150,
			render: (spend, record) => (
				<div className="flex items-center justify-center gap-x-1">
					<SpendCell
						spend={spend}
						record={record}
						successMessage="Secondary KPI Effective Cost updated"
						updateField="secondaryKPIEffectiveCost"
						refetch={refetch}
						prefix={campaignCurrency}
					/>
					<EffectiveCostCalculate
						platform={record}
						refetch={refetch}
						currency={campaignCurrency}
					/>
				</div>
			),
		},
		{
			title: 'Objective Optimization',
			dataIndex: 'rate',
			width: 180,
			key: 'objectiveOptimization',
			render: (rate, record) => {
				if (!rate || isNaN(rate) || isNaN(record.objectiveEffectiveCost)) {
					return 'N/A for objective';
				}
				const percentage =
					((rate - record.secondaryKPIEffectiveCost) / rate) * 100;

				if (record.objectiveType === 'absolute') {
					return (
						<span
							className={`${getOptimizationsColor(percentage)} font-semibold`}>
							{getFormattedNumber(percentage, 2)}%
						</span>
					);
				} else {
					return 'N/A for objective';
				}
			},
		},
		{
			title: 'Benchmark Cost',
			dataIndex: 'objectiveEffectiveCost',
			key: 'objectiveEffectiveCost',
			width: 130,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Objective Effective Cost updated."
					updateField="objectiveEffectiveCost"
					refetch={refetch}
					prefix={campaignCurrency}
				/>
			),
		},
		{
			title: 'Benchmark Optimization',
			dataIndex: 'objectiveType',
			key: 'secondaryKPIOptimization',
			width: 200,
			render: (value, record) => {
				let objectiveCost = record.objectiveEffectiveCost;
				const effectiveCost = record.secondaryKPIEffectiveCost;

				if (record.objectiveType === 'absolute') {
					const optimization =
						((objectiveCost - effectiveCost) / objectiveCost) * 100;

					if (!isFinite(optimization)) {
						return 'N/A';
					}
					return `${Math.round(optimization * 100) / 100}%`;
				} else {
					const optimization =
						((objectiveCost - effectiveCost) / objectiveCost) * 100;

					if (!isFinite(optimization)) {
						return 'N/A';
					}
					return `${Math.round(optimization * 100) / 100}%`;
				}
			},
		},
		{
			title: 'Secondary KPI',
			dataIndex: 'secondaryKPIValue',
			key: 'secondaryKPI',
			width: 120,
			render: (secondaryKPI, record) => {
				if (secondaryKPI === null || secondaryKPI === undefined) {
					return 'N/A';
				}
				return `${secondaryKPI.toLocaleString('en-EN', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})} (${record.secondaryKPIType})`;
			},
		},
		{
			title: '2nd KPI Spend',
			dataIndex: 'secondaryKPISpend',
			key: 'secondaryKPISpend',
			width: 120,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Secondary KPI Spend updated."
					updateField="secondaryKPISpend"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Delivery 2nd KPI',
			dataIndex: 'secondaryKPISpend',
			key: 'secondaryKPITotalDelivery',
			width: 160,
			render: (spend, record) =>
				spend
					? `${Math.round((spend / record.secondaryKPIValue) * 100)}%`
					: 'N/A',
		},
		{
			title: 'Business Model',
			dataIndex: 'businessModel',
			key: 'businessModel',
			width: 200,
			render: (businessModels) =>
				businessModels.map((model: string) => model).join(', '),
		},
		{
			title: 'Fee',
			dataIndex: 'fee',
			key: 'fee',
			width: 80,
		},
		{
			title: 'Buying Type',
			dataIndex: 'buyingType',
			width: 120,
			key: 'buyingType',
			render: (value, record) => {
				if (record.objectiveType === 'absolute') {
					return value;
				} else {
					return '';
				}
			},
		},

		{
			title: 'Investment',
			dataIndex: 'investment',
			key: 'investment',
			width: 105,
			render: (investment) => {
				const formattedAmount = formatMoney(
					investment,
					'en-US',
					campaignCurrency
				);
				return formattedAmount;
			},
		},
		{
			title: 'Budget',
			dataIndex: 'budget',
			key: 'budget',
			width: 105,
			render: (budget) => {
				const formattedAmount = formatMoney(budget, 'en-US', campaignCurrency);
				return formattedAmount;
			},
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			key: 'cost',
			width: 160,
			render: (spend, record) => (
				<div>
					<SpendCell
						spend={spend}
						record={record}
						successMessage="Cost updated."
						updateField="cost"
						refetch={refetch}
						prefix={campaignCurrency}
					/>
					<CostEditModal
						spend={spend}
						record={record}
						successMessage="Cost updated."
						updateField="cost"
						refetch={refetch}
						currency={campaignCurrency}
					/>
				</div>
			),
		},
		{
			title: 'Devices',
			dataIndex: 'devices',
			key: 'devices',
			width: 200,
			render: (devices) => devices.join(', '),
		},
		{
			title: 'Other Variables',
			dataIndex: 'otherVariables',
			key: 'otherVariables',
			width: 200,
			render: (variables) => variables.join(', '),
		},
		{
			title: 'VTR%',
			dataIndex: 'vtr',
			key: 'vtr',
			width: 70,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="VTR updated."
					updateField="vtr"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'CTR%',
			dataIndex: 'ctr',
			key: 'ctr',
			width: 70,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="CTR updated."
					updateField="ctr"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Reporting',
			dataIndex: 'reporting',
			key: 'reporting',
			width: 180,
			render: (reporting, record) => (
				<Select
					defaultValue={reporting || 'N/A'}
					showSearch
					optionFilterProp="children"
					filterOption={(input, option) =>
						(option!.children as unknown as string)
							.toLowerCase()
							.includes(input.toLowerCase())
					}
					style={{ width: '100%' }}
					onChange={(value) => handleReportingChange(value, record)}>
					{reportings.map((reportingOption) => (
						<Select.Option key={reportingOption} value={reportingOption}>
							{separateCamelCase(reportingOption)}
						</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: 'Final Report',
			dataIndex: 'finalReport',
			key: 'finalReport',
			width: 120,
			render: (finalReport, record) => (
				<Select
					defaultValue={finalReport || 'N/A'}
					showSearch
					optionFilterProp="children"
					filterOption={(input, option) =>
						(option!.children as unknown as string)
							.toLowerCase()
							.includes(input.toLowerCase())
					}
					style={{ width: '100%' }}
					onChange={(value) => handleFinalReportChange(value, record)}>
					{yesNoPending.map((finalReportOption) => (
						<Select.Option key={finalReportOption} value={finalReportOption}>
							{separateCamelCase(finalReportOption)}
						</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: 'IO or PO',
			dataIndex: 'ioOrPo',
			key: 'ioOrPo',
			width: 120,
			render: (ioOrPo, record) => (
				<Select
					defaultValue={ioOrPo || 'N/A'}
					showSearch
					optionFilterProp="children"
					filterOption={(input, option) =>
						(option!.children as unknown as string)
							.toLowerCase()
							.includes(input.toLowerCase())
					}
					style={{ width: '100%' }}
					onChange={(value) => handleIoOrPoChange(value, record)}>
					{yesNoPending.map((ioOrPoOption) => (
						<Select.Option key={ioOrPoOption} value={ioOrPoOption}>
							{separateCamelCase(ioOrPoOption)}
						</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: 'Screenshots',
			dataIndex: 'screenshots',
			key: 'screenshots',
			width: 120,
			render: (screenshots, record) => (
				<Select
					defaultValue={screenshots || 'N/A'}
					showSearch
					optionFilterProp="children"
					filterOption={(input, option) =>
						(option!.children as unknown as string)
							.toLowerCase()
							.includes(input.toLowerCase())
					}
					style={{ width: '100%' }}
					onChange={(value) => handleScreenshotsChange(value, record)}>
					{yesNoPending.map((screenshotsOption) => (
						<Select.Option key={screenshotsOption} value={screenshotsOption}>
							{separateCamelCase(screenshotsOption)}
						</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: 'Reconciliation',
			dataIndex: 'financialRows',
			key: 'reconciliation',
			width: 120,
			align: 'center',
			render: (val, record) => {
				const reconciledCount = val.filter(
					(row: FinancialRow) => row.reconciled === true
				).length;
				if (reconciledCount === val.length) {
					return (
						<Tooltip title="All periods reconciled">
							<CheckOutlined
								className="text-green-500"
								style={{ fontSize: 14 }}
							/>
						</Tooltip>
					);
				} else if (reconciledCount > 0) {
					return (
						<Tooltip title="Some periods reconciled">
							<DashOutlined
								className="text-amber-500"
								style={{ fontSize: 14 }}
							/>
						</Tooltip>
					);
				} else {
					return (
						<Tooltip title="No periods reconciled">
							<CloseOutlined
								className="text-red-500"
								style={{ fontSize: 14 }}
							/>
						</Tooltip>
					);
				}
			},
		},
		{
			title: 'Actions',
			dataIndex: 'notes',
			width: 100,
			fixed: 'right',
			align: 'center',
			key: 'notes',
			render: (value, record) => (
				<PlatformActions
					report={report}
					platform={record}
					refetch={refetch}
					reportId={id}
					refetchReports={refetchReports}
				/>
			),
		},
	];

	return (
		<div>
			<AddPlatformModal
				report={{ id, startDate, endDate }}
				platform={{ rate: 0, budget: 0, objectiveType: '', buyingType: '' }}
				refetch={refetch}
				refetchReports={refetchReports}
			/>

			{data?.report.platforms && data?.report.platforms.length > 0 && (
				<Table
					rowKey={(record) => record.id}
					columns={columns}
					className="platform-subtable bg-gray-50"
					size="small"
					bordered
					loading={loading}
					rowClassName="bg-white opacity-1"
					dataSource={data?.report.platforms}
					pagination={false}
					scroll={{ x: 4400, y: 500 }}
				/>
			)}
		</div>
	);
};

export default PlatformSubtable;
