import React, { useState } from 'react';
import { Button } from 'antd';
import { formatMoney } from '@/utils/currency';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import XLSX from 'sheetjs-style';
import type { SummaryTotals } from '@/routes/MediaAnalysisPage/TableSummary';
import type { MediaAnalysisRow } from '@/routes/MediaAnalysisPage/MediaAnalysisPage';
import { getFormattedNumber } from '@/lib/utils';
import {
	calculateMarginPercentage,
	calculateProfit,
	calculateProfitPercentage,
} from '@/routes/MediaAnalysisPage/utils';

interface ExportExcelButtonProps {
	data: any;
	summary: SummaryTotals;
	showRate: Boolean;
}

const ExportExcelButton: React.FC<ExportExcelButtonProps> = ({
	data,
	summary,
	showRate,
}) => {
	const [loading, setLoading] = useState(false);

	const exportToExcel = async () => {
		setLoading(true);

		if (data) {
			const excelData = data.map((item: MediaAnalysisRow) => ({
				Subcampaign: item.subcampaign || '',
				Campaign: item.campaign || '',
				'Account Manager': item.accountManager || 'N/A',
				Agency: item.agency || '',
				Client: item.client || '',
				Market: item.market?.join(', ') || '',
				Platform: item.platform || '',
				Formats: item.formats?.join(', ') || '',
				'Business Model': item.businessModel?.join(', ') || '',
				'Buying Type': item.buyingType || '',
				Rate: item.rate ? `$${item.rate}` : 'N/A',
				'Effective Cost': item.secondaryKPIEffectiveCost
					? `$${item.secondaryKPIEffectiveCost}`
					: 'N/A',
				Spend: (() => {
					if (item.objectiveType === 'budget') {
						return formatMoney(item.objectiveSpend * item.exchangeRate);
					} else if (item.objectiveType === 'absolute') {
						if (item.buyingType === 'CPM' || item.buyingType === 'CPR') {
							return formatMoney(
								(item.objectiveSpend * item.rate * item.exchangeRate) / 1000
							);
						} else {
							return formatMoney(
								item.objectiveSpend * item.rate * item.exchangeRate
							);
						}
					}
					return '';
				})(),
				Investment: formatMoney(item.investment, 'en-US', item.currency) || '',
				Cost: formatMoney(item.cost, 'en-US', item.currency) || '',
				Profit: (() => {
					if (item.cost !== undefined) {
						return formatMoney(
							calculateProfit(
								item.investment,
								item.exchangeRate,
								item.cost,
								item.incentive
							),
							'en-US',
							item.currency
						);
					} else {
						return 'N/A';
					}
				})(),
				'Margin %': (() => {
					if (
						item.rate !== null &&
						item.secondaryKPIEffectiveCost !== undefined
					) {
						const marginPercentage = calculateMarginPercentage(
							item.rate,
							item.secondaryKPIEffectiveCost
						);
						return `${getFormattedNumber(marginPercentage)}%`;
					} else {
						return '-';
					}
				})(),
				'Profit %': (() => {
					if (item.cost !== undefined && item.incentive !== undefined) {
						const profit = calculateProfitPercentage(
							item.investment,
							item.cost,
							item.incentive
						);
						return Number.isFinite(profit)
							? `${getFormattedNumber(profit)}%`
							: '-';
					} else {
						return 'N/A';
					}
				})(),
			}));

			const totalsRow = {
				Subcampaign: '',
				Campaign: '',
				'Account Manager': '',
				Agency: '',
				Client: '',
				Market: '',
				Platform: '',
				Formats: '',
				'Business Model': '',
				'Buying Type': '',
				Rate: showRate ? summary.averageRate : '',
				'Effective Cost': showRate ? summary.averageEffectiveCost : '',
				Spend: formatMoney(summary.totalSpend),
				Investment: formatMoney(summary.totalInvestment),
				Cost: formatMoney(summary.totalCost),
				Profit: formatMoney(summary.totalProfit),
				'Margin %': `${getFormattedNumber(summary.averageMargin, 2)}%`,
				'Profit %': `${getFormattedNumber(summary.averageProfit, 2)}%`,
			};

			const allRows = [...excelData, totalsRow];

			const worksheet = XLSX.utils.json_to_sheet(allRows);

			const columnWidths = [
				{ wch: 40 },
				{ wch: 40 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 20 },
				{ wch: 10 },
				{ wch: 10 },
			];

			worksheet['!cols'] = columnWidths;

			const rowHeights = allRows.map(() => ({ hpx: 25 }));
			worksheet['!rows'] = rowHeights;

			const headerCellStyle = {
				font: {
					name: 'Arial',
					sz: 13,
					bold: true,
					color: { rgb: '000000' },
				},
				fill: {
					fgColor: { rgb: 'DFDFDF' },
				},
				border: {
					top: { style: 'thin', color: { rgb: 'DFDFDF' } },
					bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
					left: { style: 'thin', color: { rgb: 'DFDFDF' } },
					right: { style: 'thin', color: { rgb: 'DFDFDF' } },
				},
				alignment: { horizontal: 'center', vertical: 'center' },
			};

			const columnCount =
				excelData.length > 0 ? Object.keys(excelData[0]).length : 0;
			const columnRange = Array.from({ length: columnCount }, (_, index) =>
				XLSX.utils.encode_cell({ r: 0, c: index })
			);

			columnRange.forEach((cell) => {
				worksheet[cell].s = headerCellStyle;
			});

			const cellStyles = {
				blackRow: {
					font: {
						name: 'Arial',
						sz: 11,
						bold: false,
						color: { rgb: '000000' },
					},
					fill: {
						fgColor: { rgb: 'FFFFFF' },
					},
					border: {
						top: { style: 'thin', color: { rgb: 'DFDFDF' } },
						bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
						left: { style: 'thin', color: { rgb: 'DFDFDF' } },
						right: { style: 'thin', color: { rgb: 'DFDFDF' } },
					},
					alignment: { horizontal: 'center', vertical: 'center' },
				},
				whiteRow: {
					font: {
						name: 'Arial',
						sz: 11,
						bold: false,
						color: { rgb: '000000' },
					},
					fill: {
						fgColor: { rgb: 'DFDFDF' },
					},
					border: {
						top: { style: 'thin', color: { rgb: 'DFDFDF' } },
						bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
						left: { style: 'thin', color: { rgb: 'DFDFDF' } },
						right: { style: 'thin', color: { rgb: 'DFDFDF' } },
					},
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			};

			excelData.forEach((rowData: MediaAnalysisRow, rowIndex: number) => {
				const cellStyle =
					rowIndex % 2 === 0 ? cellStyles.blackRow : cellStyles.whiteRow;
				Object.keys(rowData).forEach((key, colIndex) => {
					const cellAddress = XLSX.utils.encode_cell({
						r: rowIndex + 1,
						c: colIndex,
					});
					worksheet[cellAddress].s = cellStyle;
				});
			});

			allRows.forEach((rowData: MediaAnalysisRow, rowIndex: number) => {
				const cellStyle =
					rowIndex % 2 === 0 ? cellStyles.blackRow : cellStyles.whiteRow;
				Object.keys(rowData).forEach((key, colIndex) => {
					const cellAddress = XLSX.utils.encode_cell({
						r: rowIndex + 1,
						c: colIndex,
					});
					worksheet[cellAddress].s = cellStyle;
				});
			});

			const lastRowIndex = allRows.length;
			const lastRowRange = Array.from({ length: columnCount }, (_, colIndex) =>
				XLSX.utils.encode_cell({ r: lastRowIndex, c: colIndex })
			);

			lastRowRange.forEach((cell) => {
				if (worksheet[cell]) {
					worksheet[cell].s = headerCellStyle;
				}
			});

			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Media Analysis');
			const currentDate = dayjs().format('YYYY-MM-DD');
			const fileName = `Media_Analysis_${currentDate}.xlsx`;
			XLSX.writeFile(workbook, fileName);

			setLoading(false);
		}
	};

	return (
		<Button
			className="ml-1"
			type="primary"
			icon={<DownloadOutlined />}
			onClick={exportToExcel}
			loading={loading}>
			{loading ? 'Exporting .xlsx' : 'Download .xlsx'}
		</Button>
	);
};

export default ExportExcelButton;
