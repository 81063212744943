import EditableNumberInput from '@/components/EditableNumberInput';
import type { Report } from '@/interfaces/Report';
import type { ReportPlatform } from '@/interfaces/ReportPlatform';
import { UPDATE_FINANCIAL_ROW } from '@/lib/ReconciliationApi';
import { GET_PLATFORM_FINANCIAL_ROWS } from '@/lib/reportsApi';
import { capitalize } from '@/lib/utils';
import CostEditModal from '@/routes/ReconciliationPage/CostEditModal';
import type { FinancialRow } from '@/routes/ReconciliationPage/ReconciliationPage';
import { formatMoney } from '@/utils/currency';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Divider, Form, message, Modal, Row, Table } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

type Props = {
	report: Report;
	setOpen: (newOpen: string) => void;
	open: boolean;
	platform: ReportPlatform;
	rows: {
		id: string;
		cost: number;
		startDate: string;
		endDate: string;
		monthlyBilling: number;
		commnent: string;
		reconciled: boolean;
		billed: boolean;
	}[];
};

type GetPlatformFinancialRowsData = {
	reportPlatform: {
		id: string;
		financialRows: {
			id: string;
			cost: number;
			startDate: string;
			endDate: string;
			monthlyBilling: number;
			commnent: string;
			reconciled: boolean;
			billed: boolean;
		}[];
	};
};

const ReconciliationModal = ({ open, setOpen, platform, report }: Props) => {
	const [updateRowMutation, { loading: updateRowLoading }] = useMutation<
		FinancialRow,
		{ row: { rowID: string; data: Partial<FinancialRow> } }
	>(UPDATE_FINANCIAL_ROW);

	const { data, loading, refetch } = useQuery<
		GetPlatformFinancialRowsData,
		{ id: string }
	>(GET_PLATFORM_FINANCIAL_ROWS, {
		variables: { id: platform.id },
	});

	const rows = data?.reportPlatform.financialRows;

	const updateFinancialRow = async (
		rowId: string,
		field: string,
		value: any
	) => {
		const res = await updateRowMutation({
			variables: {
				row: { rowID: rowId, data: { [field]: value } },
			},
		});

		if (res.data) {
			message.success(`${capitalize(field)} value updated!`);
		} else if (res.errors) {
			message.error(`Error while updating ${field}`);
		}

		await refetch();
	};

	const [form] = Form.useForm();

	return (
		<Modal
			onCancel={() => setOpen('none')}
			cancelButtonProps={{ style: { display: 'none' } }}
			onOk={() => setOpen('none')}
			open={open}
			width={950}
			title="Reconcile subcampaign">
			<div className="mb-1">
				<p className="my-0 text-lg font-medium"> {platform.name}</p>
				<p className="my-0 text-sm font-light text-gray-500">
					{dayjs(platform.startDate).format('DD/MM/YYYY')} -{' '}
					{dayjs(platform.endDate).format('DD/MM/YYYY')}
				</p>
			</div>
			<Row>
				<Col span={12}>
					<div>
						<span className="font-medium">Client: </span>
						<span>{report.client.name}</span>
					</div>
					<div>
						<span className="font-medium">Platform: </span>
						<span>{platform.platform.name}</span>
					</div>
					<div>
						<span className="font-medium">Business Model: </span>
						<span>{platform.businessModel.join(', ')}</span>
					</div>
					<div>
						<span className="font-medium">Investment: </span>
						<span>
							{formatMoney(platform.investment, 'en-US', report.currency)}
						</span>
					</div>
				</Col>
				<Col span={12}>
					<div>
						<span className="font-medium">Agency: </span>
						<span>{report.agency.name}</span>
					</div>
					<div>
						<span className="font-medium">Platform: </span>
						<span>{platform.formats.map((f) => f.name).join(', ')}</span>
					</div>

					<div>
						<span className="font-medium">Rate: </span>
						<span>
							{platform.objectiveType === 'absolute'
								? `${platform.buyingType} (${formatMoney(
										platform.rate,
										'en-US',
										report.currency
								  )})`
								: 'N/A'}
						</span>
					</div>
					<div>
						<span className="font-medium">Reconciled: </span>
						<span>{platform.reconciliation ? 'Yes' : 'No'}</span>
					</div>
				</Col>
			</Row>

			<Divider className="mt-2 mb-4" />
			<Form>
				<Table
					size="small"
					rowKey={'id'}
					loading={updateRowLoading || loading}
					bordered
					pagination={false}
					dataSource={rows}
					columns={[
						{
							title: 'Period',
							dataIndex: 'startDate',
							width: 110,
							key: 'startDate',
							render: (_, { startDate, endDate }) =>
								`${dayjs(startDate).format('DD/MM')} - ${dayjs(endDate).format(
									'DD/MM'
								)}`,
						},
						{
							title: 'Monthly Billing',
							dataIndex: 'monthlyBilling',
							key: 'monthlyBilling',
							render: (val, record) => (
								<Form.Item
									name={`monthlyBilling-${record.id}`}
									style={{ marginBottom: 0 }}
									initialValue={val}
									rules={[
										{ required: true, message: '' },
										{ type: 'number', min: 0, message: '' },
										{
											type: 'number',
											max: platform.investment,
											warningOnly: true,
											message: 'Value higher than investment',
										},
									]}>
									<EditableNumberInput
										inputValue={val}
										handleConfirm={() => {
											let v = form.getFieldValue(`monthlyBilling-${record.id}`);

											updateFinancialRow(record.id, 'monthlyBilling', v);
										}}
										handleChange={(value) =>
											form.setFieldValue(`monthlyBilling-${record.id}`, value)
										}
										inputProps={{
											disabled: updateRowLoading,
											prefix: report.currency,
										}}
									/>
								</Form.Item>
							),
						},
						{
							title: 'Cost',
							dataIndex: 'cost',
							key: 'cost',
							render: (val, record) => {
								return (
									<div>
										<Form.Item
											name={`cost-${record.id}`}
											initialValue={val}
											style={{ marginBottom: 0 }}
											rules={[
												{ required: true, message: '' },
												{ type: 'number', min: 0, message: '' },
												{
													type: 'number',
													max: platform.investment,
													warningOnly: true,
													message: 'Value higher than investment',
												},
											]}>
											<EditableNumberInput
												inputValue={val}
												handleConfirm={() => {
													let v = form.getFieldValue(`cost-${record.id}`);

													updateFinancialRow(record.id, 'cost', v);
												}}
												handleChange={(value) =>
													form.setFieldValue(`cost-${record.id}`, value)
												}
												inputProps={{
													disabled: updateRowLoading,
													prefix: report.currency,
												}}
											/>
										</Form.Item>
										<CostEditModal
											form={form}
											rowId={record.id}
											spend={val}
											refetch={refetch}
											currency={report.currency}
										/>
									</div>
								);
							},
						},

						{
							title: 'Reconciled',
							dataIndex: 'reconciled',
							key: 'reconciled',
							width: 120,
							align: 'center',
							render: (val, record) => (
								<Form.Item
									name={`reconciled-${record.id}`}
									noStyle
									valuePropName="checked"
									initialValue={val}>
									<Checkbox
										style={{ zIndex: 1 }}
										disabled={updateRowLoading}
										onChange={(event) =>
											updateFinancialRow(
												record.id,
												'reconciled',
												event.target.checked
											)
										}
									/>
								</Form.Item>
							),
						},
						{
							title: 'Billed',
							dataIndex: 'billed',
							key: 'billed',
							width: 120,
							align: 'center',
							render: (val, record) => (
								<Form.Item
									name={`billed-${record.id}`}
									noStyle
									valuePropName="checked"
									initialValue={val}>
									<Checkbox
										style={{ zIndex: 1 }}
										disabled={updateRowLoading}
										onChange={(event) =>
											updateFinancialRow(
												record.id,
												'billed',
												event.target.checked
											)
										}
									/>
								</Form.Item>
							),
						},
						{
							title: 'Comment',
							dataIndex: 'comment',
							key: 'comment',
							width: 300,
							render: (val, record) => (
								<Form.Item
									name={`comment-${record.id}`}
									initialValue={val}
									style={{ marginBottom: 0 }}>
									<TextArea
										disabled={updateRowLoading}
										onBlur={(element) => {
											updateFinancialRow(
												record.id,
												'comment',
												element.target.value
											);
										}}
										className="mb-0 w-full"
										autoSize={{ minRows: 1, maxRows: 2 }}
										style={{ resize: 'vertical' }}
									/>
								</Form.Item>
							),
						},
					]}
				/>
			</Form>
		</Modal>
	);
};

export default ReconciliationModal;
