const calculateObjectiveValue = (
	budget: number | undefined,
	rate: number | undefined,
	objectiveType: string,
	buyingType: string | undefined
): number => {
	if (!budget || !rate || rate === 0) {
		return 0;
	}

	if (objectiveType === 'absolute') {
		if (buyingType === 'CPM' || buyingType === 'CPR') {
			return Math.round((budget / rate) * 1000);
		} else {
			return Math.round(budget / rate);
		}
	}

	return 0;
};

export { calculateObjectiveValue };
