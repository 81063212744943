import { getFormattedNumber } from '@/lib/utils';
import { Form, InputNumber } from 'antd';

type Props = {
	inputProps?: React.ComponentProps<typeof InputNumber>;
	formProps?: React.ComponentProps<typeof Form.Item>;
};

const NumberInput = (props: Props) => {
	const handleChange = (value: any) => {
		//console.log(value);
	};

	const inputParser = (value: any) => {
		let val = value;
		if (val.includes(',')) {
			val = val.replace(',', '.');
		}
		return val;
	};

	return (
		<Form.Item {...props.formProps}>
			<InputNumber
				className="w-full"
				formatter={(value, info) => {
					if (value) {
						return getFormattedNumber(value as number, 2);
					} else {
						return `${value}`;
					}
				}}
				//parser={inputParser}
				onChange={handleChange}
				{...props.inputProps}
			/>
		</Form.Item>
	);
};

export default NumberInput;
