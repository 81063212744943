import { AllSelectsData } from '@/lib/listsApi';
import dayjs from 'dayjs';
import _ from 'lodash';

const checkMandatoryFields = (data: any) => {
	const mandatoryFields = [
		'c_country',
		'c_market',
		'c_agency',
		'c_client',
		'c_name',
		'c_startDate',
		'c_endDate',
		'c_investment',
		'c_budget',
		'c_currency',
		'c_exchangeRate',
		'c_accountManager',
		'c_planner',
		'p_name',
		'p_businessModel',
		'p_startDate',
		'p_endDate',
		'p_buyingType',
		'p_rate',
		'p_objectiveType',
		'p_objectiveValue',
	];
	const neededMandatoryFields = {
		campaign: [] as string[],
		platform: [] as string[],
	};

	data.forEach((row: any) => {
		mandatoryFields.map((m: string) => {
			let fieldName = '';
			if (row.column_type === 'campaign' && m.indexOf('p_')) {
				//checks only mandatory campaign fields
				fieldName = m.replace('c_', '');
				if (
					_.isNull(row[m]) &&
					!neededMandatoryFields.campaign.includes(fieldName)
				) {
					neededMandatoryFields.campaign.push(fieldName);
				}
			} else if (row.column_type === 'platform' && m.indexOf('c_')) {
				//checks only mandatory platform fields
				fieldName = m.replace('p_', '');
				if (
					_.isNull(row[m]) &&
					!neededMandatoryFields.platform.includes(fieldName)
				) {
					neededMandatoryFields.platform.push(fieldName);
				}
			}
		});
	});

	return neededMandatoryFields;
};

const checkRelationshipFields = (
	parsedInfo: any,
	existingData: AllSelectsData,
	emptyMandatory: boolean
) => {
	const neededRelationshipFields = {
		salesperson: [] as { name: string }[],
		country: [] as { name: string }[],
		agency: [] as { name: string }[],
		product: [] as { name: string }[],
		client: [] as { name: string }[],
		accountManager: [] as { name: string }[],
		format: [] as { name: string }[],
		platform: [] as { name: string }[],
		planner: [] as { name: string }[],
		billingCompany: [] as { name: string }[],
	};
	let campaignRelationshipFields: { keyName: string; name: any }[] = [];
	let platformRelationshipFields: { keyName: string; name: any }[] = [];

	if (!emptyMandatory) {
		parsedInfo.forEach((info: any, index: number) => {
			if (info.campaign === null) {
				return;
			}

			if (info.column_type === 'campaign') {
				const campaignEntries = getNeededCamapaignFields(
					info,
					existingData,
					campaignRelationshipFields
				);
			} else if (info.column_type === 'platform') {
				const platformEntries = getNeededPlatformFields(
					info,
					existingData,
					platformRelationshipFields
				);
			}
		});

		if (campaignRelationshipFields.length > 0) {
			getNeededRelationshipFieldsValue(
				campaignRelationshipFields,
				neededRelationshipFields
			);
		}
		if (platformRelationshipFields.length > 0) {
			getNeededRelationshipFieldsValue(
				platformRelationshipFields,
				neededRelationshipFields
			);
		}
	}

	return neededRelationshipFields;
};

function getNeededRelationshipFieldsValue(
	fields: any,
	neededRelationshipFields: any
) {
	let isDuplicated = false;
	fields.forEach((item: any, index: number) => {
		const key = item.keyName as keyof typeof neededRelationshipFields;
		neededRelationshipFields[key].forEach((f: any) => {
			if (f.name === item.name) {
				isDuplicated = true;
			}
		});
		if (!isDuplicated) {
			neededRelationshipFields[key].push({ name: item.name });
		}
	});
}

const getNeededCamapaignFields = (
	info: any,
	existingData: AllSelectsData,
	campaignRelationshipFields: any
) => {
	const country = existingData.countries.find(
		(c) => c.name === info.c_country
	)?.id;
	const agency = existingData.agencies.find(
		(c) => c.name === info.c_agency
	)?.id;
	const salesperson = existingData.salespeople.find(
		(c) => c.name === info.c_salesperson
	)?.id;
	const accountManager = existingData.accountManagers.find(
		(c) => c.name === info.c_accountManager
	)?.id;
	const products = info.c_product ? info.c_product.split(', ') : '';
	const product = info.c_product
		? products.map(
				(p: any) => existingData.products.find((c) => c.name === p)?.id
		  )
		: undefined;
	const client = existingData.clients.find((c) => c.name === info.c_client)?.id;
	const planner = existingData.planners.find(
		(c) => c.name === info.c_planner
	)?.id;
	const billingCompany = existingData.billingCompanies.find(
		(c) => c.name === info.c_billingCompany
	)?.id;

	Object.entries({
		country,
		agency,
		salesperson,
		product,
		client,
		accountManager,
		planner,
		billingCompany,
	}).forEach((item) => {
		const key = item[0];
		if (key === 'product' && item[1]) {
			item[1].forEach((it: any, index: number) => {
				if (it === undefined) {
					campaignRelationshipFields.push({
						keyName: key,
						name: products[index],
					});
				}
			});
		} else if (key !== 'product' && item[1] === undefined) {
			campaignRelationshipFields.push({ keyName: key, name: info['c_' + key] });
		}
	});

	return campaignRelationshipFields;
};

const getNeededPlatformFields = (
	info: any,
	existingData: AllSelectsData,
	platformRelationshipFields: any
) => {
	const platform = existingData.platforms.find(
		(c) => c.name === info.p_platform
	)?.id;
	const formats = info.p_format ? info.p_format.split(', ') : '';
	const format = info.p_format
		? formats.map(
				(f: any) => existingData.formats.find((c) => c.name === f)?.id
		  )
		: undefined;

	Object.entries({
		platform,
		format,
	}).forEach((item) => {
		const key = item[0];
		if (key === 'format' && item[1]) {
			item[1].forEach((it: any, index: number) => {
				if (it === undefined) {
					platformRelationshipFields.push({
						keyName: key,
						name: formats[index],
					});
				}
			});
		} else if (key !== 'format' && item[1] === undefined) {
			platformRelationshipFields.push({ keyName: key, name: info['p_' + key] });
		}
	});

	return platformRelationshipFields;
};

const checkFieldValidation = (parsedInfo: any, emptyMandatory: boolean) => {
	let value1 = parsedInfo[0];
	let value2 = [];
	const needsFieldsValidation = {
		campaignBudget: [] as { name: string }[],
		platformBudget: [] as { name: string }[],
		platformInvestment: [] as { name: string }[],
		platformStartDate: [] as { name: string }[],
		platformEndDate: [] as { name: string }[],
	};

	if (!emptyMandatory) {
		parsedInfo.forEach((item: any, i: number) => {
			if (
				value1.column_type === item.column_type &&
				parsedInfo[i].column_type === 'campaign'
			) {
				value1 = parsedInfo[i];
				value2 = parsedInfo[i + 1];
			} else if (
				value1.column_type !== item.column_type &&
				value1.column_type === 'campaign' &&
				item.column_type === 'platform'
			) {
				value2 = parsedInfo[i];
				getValueNeededFields(value1, value2, needsFieldsValidation);
			}

			if (item.column_type === 'campaign' && item.c_budget > item.c_investment)
				needsFieldsValidation.campaignBudget.push({
					name: item.c_name,
				});

			if (item.column_type === 'platform' && item.p_budget > item.p_investment)
				needsFieldsValidation.platformBudget.push({
					name: item.p_name,
				});
		});
	}

	return needsFieldsValidation;
};

const getValueNeededFields = (
	value1: any,
	value2: any,
	needsFieldsValidation: any
) => {
	if (value2.p_investment > value1.c_investment) {
		needsFieldsValidation.platformInvestment.push({
			name:
				'Platform ' + value2.p_name + ' belonging to campaign ' + value1.c_name,
		});
	}
	if (dayjs(value2.p_startDate).isBefore(dayjs(value1.c_startDate))) {
		needsFieldsValidation.platformStartDate.push({
			name:
				'Platform ' + value2.p_name + ' belonging to campaign ' + value1.c_name,
		});
	}
	if (dayjs(value2.p_endDate).isAfter(dayjs(value1.c_endDate))) {
		needsFieldsValidation.platformEndDate.push({
			name:
				'Platform ' + value2.p_name + ' belonging to campaign ' + value1.c_name,
		});
	}
};

const getDescription = (item: any) => {
	if (item === 'campaignBudget') {
		return 'Budget is greater than its investment';
	} else if (item === 'platformBudget') {
		return 'Budget is greater than its investment';
	} else if (item === 'platformInvestment') {
		return 'Platform investment is greater than campaign investment';
	} else if (item === 'platformStartDate') {
		return 'Platform start date is before than campaign start date';
	} else if (item === 'platformEndDate') {
		return 'Platform end date is after than campaign end date';
	}
};

const getReportData = (
	parsedInfo: any,
	allSelects: AllSelectsData | undefined
) => {
	let tempReport: any = {};
	let reportArray: any[] = [];
	let index = 0;

	for (const row of parsedInfo) {
		if (row.column_type === 'campaign') {
			Object.keys(row).forEach(function (key) {
				key.indexOf('p_') == 0 && delete row[key];
			});
			tempReport = getReportSchema(row, allSelects);
			pushCampaignReport(parsedInfo, index, tempReport, reportArray);
		} else if (row.column_type === 'platform') {
			Object.keys(row).forEach(function (key) {
				key.indexOf('c_') == 0 && delete row[key];
			});
			const platform = getPlatformSchema(row, allSelects);
			tempReport.platforms.create.push(platform);
			pushPlatformReport(parsedInfo, index, tempReport, reportArray);
		}
		index++;
	}

	return reportArray;
};

const getReportSchema = (row: any, allSelects: any) => {
	const businessModels: string[] = [];

	if (row.c_businessModel) {
		row.c_businessModel
			.split(', ')
			.map((variable: string) => businessModels.push(variable));
	}

	return {
		salesperson: {
			connect: {
				id: allSelects?.salespeople.find(
					(c: { name: any }) => c.name === row.c_salesperson
				)?.id,
			},
		},
		country: {
			connect: {
				id: allSelects?.countries.find(
					(c: { name: any }) => c.name === row.c_country
				)?.id,
			},
		},
		agency: {
			connect: {
				id: allSelects?.agencies.find(
					(c: { name: any }) => c.name === row.c_agency
				)?.id,
			},
		},
		...(row.c_product && {
			product: {
				connect: row.c_product?.split(', ').map((product: string) => ({
					id: allSelects?.products.find(
						(c: { name: string }) => c.name === product
					)?.id,
				})),
			},
		}),
		accountManager: {
			connect: {
				id: allSelects?.accountManagers.find(
					(c: { name: any }) => c.name === row.c_accountManager
				)?.id,
			},
		},
		client: {
			connect: {
				id: allSelects?.clients.find(
					(c: { name: any }) => c.name === row.c_client
				)?.id,
			},
		},
		...(row.c_market && {
			market: {
				connect: row.c_market?.split(', ').map((market: string) => ({
					id: allSelects?.countries.find(
						(c: { name: string }) => c.name === market
					)?.id,
				})),
			},
		}),
		billingCompany: {
			connect: {
				id: allSelects?.billingCompanies.find(
					(c: { name: any }) => c.name === row.c_billingCompany
				)?.id,
			},
		},
		planner: {
			connect: {
				id: allSelects?.planners.find(
					(c: { name: any }) => c.name === row.c_planner
				)?.id,
			},
		},
		platforms: { create: [] },
		campaign: row.c_name,
		investment: row.c_investment,
		billing: row.c_billing,
		budget: row.c_budget,
		exchangeRate: row.c_exchangeRate,
		currency: row.c_currency || 'USD',
		orderNumber: row.c_orderNumber !== null ? `${row.c_orderNumber}` : 'S/N',
		billNumber: row.c_billNumber !== null ? `${row.c_billNumber}` : 'S/N',
		startDate: dayjs(row.c_startDate).format('YYYY-MM-DD'),
		endDate: dayjs(row.c_endDate).format('YYYY-MM-DD'),
		billingMonthStart:
			row.c_billingMonth !== null
				? dayjs(row.c_billingMonth).format('YYYY-MM-DD')
				: null,
		billingMonthEnd:
			row.c_billingMonth !== null
				? dayjs(row.c_billingMonth).format('YYYY-MM-DD')
				: null,
		...(row.c_businessModel && { businessModel: businessModels }),
		...(row.c_Fee && { fee: row.c_Fee }),
	};
};

const getPlatformSchema = (row: any, allSelects: any) => {
	const devices: string[] = [];
	const otherVariables: string[] = [];
	const businessModel: string[] = [];

	row.p_devices.split(', ').map((device: string) => devices.push(device));
	row.p_otherVariables
		.split(', ')
		.map((variable: string) => otherVariables.push(variable));
	row.p_businessModel
		.split(', ')
		.map((variable: string) => businessModel.push(variable));

	return {
		name: row.p_name,
		platform: {
			connect: {
				id: allSelects?.platforms.find(
					(c: { name: any }) => c.name === row.p_platform
				)?.id,
			},
		},
		...(row.p_format && {
			formats: {
				connect: row.p_format?.split(', ').map((format: string) => ({
					id: allSelects?.formats.find(
						(c: { name: string }) => c.name === format
					)?.id,
				})),
			},
		}),
		buyingType: row.p_buyingType,
		...(row.p_businessModel && { businessModel: businessModel }),
		fee: row.p_Fee,
		startDate: dayjs(row.p_startDate).format('YYYY-MM-DD'),
		endDate: dayjs(row.p_endDate).format('YYYY-MM-DD'),
		...(row.p_devices && { devices: devices }),
		...(row.p_otherVariables && { otherVariables: otherVariables }),
		rate: row.p_rate,
		...(row.p_objectiveType && {
			objectiveType: row.p_objectiveType.toLowerCase(),
		}),
		objectiveValue: row.p_objectiveValue,
		secondaryKPIType: row.p_secondaryKPIType,
		secondaryKPIValue: row.p_secondaryKPIValue,
		...(row.p_market && {
			formats: {
				connect: row.p_market?.split(', ').map((market: string) => ({
					id: allSelects?.countries.find(
						(c: { name: string }) => c.name === market
					)?.id,
				})),
			},
		}),
		cost: row.p_cost,
		investment: row.p_investment,
		budget: row.p_budget,
		ctr: row.p_CTR,
		vtr: row.p_VTR,
		reporting: row.p_reporting,
		finalReport: row.p_finalReport.toLowerCase(),
		ioOrPo: row.p_ioPo.toLowerCase(),
		screenshots: row.p_screenshots.toLowerCase(),
		reconciliation: row.p_reconciliation.toLowerCase(),
	};
};

const pushCampaignReport = (
	parsedInfo: any,
	index: number,
	tempReport: any,
	reportArray: any
) => {
	let item = '';
	if (index + 1 <= parsedInfo.length) {
		if (parsedInfo[index + 1] === undefined) {
			item = parsedInfo[index].column_type;
		} else {
			item = parsedInfo[index + 1].column_type;
		}

		if (item === 'campaign') {
			if (tempReport.platforms.create.length === 0) {
				tempReport.platforms = null;
				return reportArray.push(tempReport);
			}
		}
	}
};

const pushPlatformReport = (
	parsedInfo: any,
	index: number,
	tempReport: any,
	reportArray: any
) => {
	let item = '';
	let csvEndFile = false;

	if (index + 1 <= parsedInfo.length) {
		if (parsedInfo[index + 1] === undefined) {
			csvEndFile = true;
		} else {
			item = parsedInfo[index + 1].column_type;
		}

		if (item === 'campaign' || csvEndFile) {
			if (tempReport.platforms.create.length === 0) {
				tempReport.platforms = null;
			}
			return reportArray.push(tempReport);
		}
	}
};

const months = {
	ene: 0,
	feb: 1,
	mar: 2,
	abr: 3,
	may: 4,
	jun: 5,
	jul: 6,
	ago: 7,
	sept: 8,
	oct: 9,
	nov: 10,
	dic: 11,
};

const createDates = (monthString: string) => {
	const monthName = monthString.split('-')[0] as keyof typeof months;
	const monthNumber = months[monthName];
	const year = 2000 + parseInt(monthString.split('-')[1]);
	const startDate = dayjs({
		year: year,
		month: monthNumber,
		day: 1,
		hour: 10,
	});
	const endDate = dayjs({ year: year, month: monthNumber, day: 15, hour: 10 });
	return {
		startDate: startDate.toISOString().split('T')[0],
		endDate: endDate.toISOString().split('T')[0],
	};
};

export {
	checkMandatoryFields,
	checkRelationshipFields,
	createDates,
	checkFieldValidation,
	getReportData,
	getDescription,
};
