import { ReportFormData } from '@/interfaces/Report';
import {
	CreateReportData,
	CreateReportVariables,
	CREATE_REPORT_MUTATION,
	GLU_CAMPAIGN_ID_COUNT_QUERY,
	ReportsCountData,
	ReportsCountVariables,
	REPORTS_QUERY,
} from '@/lib/reportsApi';
import { generateGIDPrefix } from '@/lib/utils';
import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import { Dayjs } from 'dayjs';
import { FC, useEffect, useState, useContext } from 'react';
import InputDataForm from './InputDataForm';

import { AuthContext } from '../../context/AuthContext';

type Props = {};

const InputDataModal: FC<Props> = (props: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [createReport, { data }] = useMutation<
		CreateReportData,
		CreateReportVariables
	>(CREATE_REPORT_MUTATION);

	const { user } = useContext(AuthContext);

	const [form] = Form.useForm<ReportFormData>();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (data) {
			setIsModalOpen(false);
			form.resetFields();
		}
	}, [data, form]);

	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const convertDateToString = (startDate: Dayjs, endDate: Dayjs): string => {
		const startingMonth = startDate.month();
		const endMonth = endDate.month();

		let billingMonths = [];

		if (endDate.year() === startDate.year()) {
			for (let i = startingMonth; i <= endMonth; i++) {
				billingMonths.push(`${months[i]}-${startDate.format('YY')}`);
			}
		} else {
			for (let i = startingMonth; i <= 11; i++) {
				billingMonths.push(`${months[i]}-${startDate.format('YY')}`);
			}
			for (let i = 0; i <= endMonth; i++) {
				billingMonths.push(`${months[i]}-${endDate.format('YY')}`);
			}
		}

		const fromServer = billingMonths.join(', ');

		return fromServer;
	};

	const handleFormSubmit = async (values: ReportFormData) => {
		console.log('handleFormSubmithandleFormSubmit', values);

		const data = {
			...values,
			accountManager: { connect: { id: values.accountManager } },
			agency: { connect: { id: values.agency } },
			billingCompany: values.billingCompany
				? { connect: { id: values.billingCompany } }
				: null,
			campaign: values.campaign,
			client: { connect: { id: values.client } },
			country: { connect: { id: values.country } },
			market: values.market
				? { connect: values.market.map((m: string) => ({ id: m })) }
				: null,
			product: values.product
				? { connect: values.product.map((f: string) => ({ id: f })) }
				: null,
			salesperson: values.salesperson
				? { connect: { id: values.salesperson } }
				: null,
			billingMonth: null,
			billingMonthStart: values.billingMonth
				? values.billingMonth![0].startOf('month').format('YYYY-MM-DD')
				: null,
			billingMonthEnd: values.billingMonth
				? values.billingMonth![1].startOf('month').format('YYYY-MM-DD')
				: null,
			startDate: values.timePeriod![0].format('YYYY-MM-DD'),
			endDate: values.timePeriod![1].format('YYYY-MM-DD'),
			planner: values.planner ? { connect: { id: values.planner } } : null,
			platforms:
				values.platforms !== undefined
					? {
							create: values.platforms.map((p) => {
								const platform = {
									...p,
									startDate: p.timePeriod![0].format('YYYY-MM-DD'),
									endDate: p.timePeriod![1].format('YYYY-MM-DD'),
									market: p.market
										? { connect: p.market.map((m) => ({ id: m })) }
										: null,
									platform: p.platform ? { connect: { id: p.platform } } : null,
									formats: p.formats
										? { connect: p.formats.map((f) => ({ id: f })) }
										: null,
								};

								delete platform.calculatedValue;
								delete platform.timePeriod;
								return platform;
							}),
					  }
					: undefined,
		};

		delete data.timePeriod;
		createReport({
			variables: {
				data,
			},
			refetchQueries: [{ query: REPORTS_QUERY }],
		});
	};

	return (
		<div className="mb-4">
			<Button
				size="middle"
				type="primary"
				icon={<PlusOutlined />}
				onClick={showModal}
				style={{
					display:
						user?.permission?.rowDataCreateCampaign === 'yes'
							? 'block'
							: 'none',
				}}>
				New data
			</Button>
			<Modal
				bodyStyle={{
					maxHeight: '650px',
					overflowY: 'scroll',
					paddingRight: '15px',
				}}
				width={650}
				title="New data"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				keyboard
				footer={[
					<Button key="cancel" onClick={handleCancel} danger>
						Cancel
					</Button>,
					<Button
						form="dataInput"
						key="submit"
						htmlType="submit"
						type="primary">
						Submit
					</Button>,
				]}>
				<InputDataForm form={form} handleFormSubmit={handleFormSubmit} />
			</Modal>
		</div>
	);
};

export default InputDataModal;
