import useDebounce from '@/hooks/useDebounce';
import { Report } from '@/interfaces/Report';
import { UPDATE_REPORT_MUTATION } from '@/lib/reportsApi';
import { useMutation } from '@apollo/client';
import { message, Modal } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createEditor } from 'slate';

import { Slate, Editable, withReact } from 'slate-react';
import Element from './NotesEditor/NoteElement';
import Leaf from './NotesEditor/NoteLeaf';

type Props = {
	notes: any;
	report: Report;
	refetch: any;
	open: boolean;
	setOpen: (newOpen: string) => void;
};

const NotesCampaignModal: FC<Props> = ({
	notes,
	report,
	refetch,
	open,
	setOpen,
}: Props) => {
	const [updatedValues, setUpdatedValues] = useState<any>();

	const [editorValue, setEditorValue] = useState<any>([
		{ type: 'paragraph', children: [{ text: '' }] },
	]);

	const renderElement = useCallback((props: any) => <Element {...props} />, []);
	const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

	const editor = useMemo(() => withReact(createEditor()), []);

	const [updateReportMutation, { data, error }] = useMutation(
		UPDATE_REPORT_MUTATION
	);

	const debouncedValues = useDebounce<any>(updatedValues, 1000);

	const handleOk = () => {
		setOpen('none');
	};

	const handleCancel = () => {
		setOpen('none');
	};

	const updateValues = async () => {
		await updateReportMutation({
			variables: { report: report.id, data: { notes: debouncedValues } },
		});
		refetch();
	};

	useEffect(() => {
		if (debouncedValues) {
			updateValues();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedValues]);

	const handleSlateChange = (value: any) => {
		setEditorValue(value);
		if (value !== notes?.document) {
			setUpdatedValues(value);
		}
	};

	useEffect(() => {
		if (data) {
			message.success('Updated Notes', 0.5);
		} else if (error) {
			message.error(error.message);
		}
	}, [data, error]);

	useEffect(() => {
		if (notes?.document) {
			setEditorValue(notes.document);
		}
	}, [notes]);

	return (
		<div>
			<Modal
				width={650}
				bodyStyle={{ paddingTop: '20px' }}
				title={'Edit notes'}
				open={open}
				onOk={handleOk}
				destroyOnClose={true}
				onCancel={handleCancel}
				keyboard
				footer="">
				<Slate editor={editor} value={editorValue} onChange={handleSlateChange}>
					<div className="shadow-inner-sm rounded border border-solid border-slate-200 bg-gray-50 px-2 py-2">
						<div>
							<Editable
								renderElement={renderElement}
								renderLeaf={renderLeaf}
								placeholder="Write a note"
							/>
						</div>
					</div>
				</Slate>
			</Modal>
		</div>
	);
};

export default NotesCampaignModal;
